.countdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    max-width: 840px;
    margin: 0 auto;
    .countdown-item {
        color: var(--y-grey3);
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        line-height: 30px;
        margin: 10px 40px;
        padding-top: 10px;
        position: relative;
        width: 100px;
        height: 100px;
        font-family: PoppinsBold;
        span {
            color: var(--y-grey3);
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            font-family: PoppinsRegular;
        }

        .countdown-svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100px;
            height: 100px;
        }

        @media only screen and (max-width: 620px) {
            margin: 10px 5px;
            
        }
    }

    &.fitted {
        .countdown-item {
            margin: 10px 10px;
            @media only screen and (max-width: 620px) {
                margin: 10px 5px;
                
            }
        }
    }
}
