.p-avatar {
    padding-bottom: 20px;
    border-bottom: solid 1px #979797;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .p-image {
        width: 51px;
        height: 51px;
        border-radius: 51px;
        background-color: #d8d8d8;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        i {
            font-size: 30px;
            &:before {
                color: white;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .p-name {
        font-family: PoppinsSemiBold;
        font-size: 14px;
        color: var(--y-grey3);
        
    }
} 
.p-menu-items {
    padding-top: 20px;
    .item {
        display: block;
        margin-bottom: 20px;
        color: var(--y-grey3);
        font-family: PoppinsRegular;
        font-size: 14px;
        cursor: pointer;
        transition: color 0.1s ease-in;
        &:hover {
            color: var(--y-green1);
            i {
                &::before{
                    color: var(--y-green1);
                }
                
            }
        }
        a {
            text-decoration: none;
            color: inherit;
            position: relative;
            
        }
        i {
            transition: color 0.1s ease-in;
            margin-right: 10px;
            &::before{
                color: var(--y-grey3);
            }
            
        }
        svg {
            transition: color 0.1s ease-in;
            margin-right: 10px;
        }
        &.active {
            font-family: PoppinsBold;
        }
    }
}

.sticked {
    position: sticky;
    top: 10px;
}