.ui {
    &.modals {
        .expired-modal {
            .top-header-block {
                &.header {
                    border-bottom: none!important;
                    text-align: right;
                    padding-bottom:1.25rem!important;
                    background: transparent!important;
                    i {
                        font-size: 20px;
                        &::before {
                            color: var(--y-grey3);
                        }
                    }
                    @media only screen and (max-width: 590px) {
                        padding:10px 10px 0 10px!important;
                      }
                }
                
        
            }
            .content {
                padding-top: 0!important;
                background: transparent!important;
                .description-block {
                    text-align: center;
                    .description-title {
                        font-family: PoppinsRegular!important;
                        color: var(--y-grey3);
                        margin: 0;
                        font-size: 18px;
                        &:first-child{
                            font-family: PoppinsBold!important;
                            margin-bottom: 20px;
                        }
                    }
                }
        
                .expired-cards {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin: 40px auto;
                    &__item {
                        flex-shrink: 0;
                        flex-grow: 0;
                        flex-basis: 48%;
                        background-color: white;
                        overflow: hidden;
                        border-radius: 10px;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        display: flex;
                        flex-direction: column;
                        @media only screen and (max-width: 590px) {
                            flex-basis: 100%;
                            margin-bottom: 20px;
                          }
                    }
                    &__header {
                        background: var(--y-grey3);
                        height: 50px;
                        padding: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                    }
                    &__corner {
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-style: solid;
                        border-color: var(--y-green1) transparent transparent var(--y-green1);
                        height: 0;
                        width: 0;
                        border-width: 80px 80px 0px 0px;
                    }
                    &__corner-text {
                        color: #eee;
                        font-size: 12px;
                        position: relative;
                        left: 6.1px;
                        top: -54.4px;
                        white-space: nowrap;
                        -webkit-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                        width: 15px;
                        text-align: center;
                        line-height: 2em;
                        text-transform: uppercase;
                        font-family: PoppinsBold;
                    }
                    &__title {
                        color: #fff;
                        font-family: PoppinsSemiBold;
                        font-size: 16px;
                        text-align: center;
                        text-transform: uppercase;
                    }
                    &__body {
                        padding: 20px 20px 0px 20px;
                        
                    }
                    &__options {
                        padding: 20px 20px 0px 20px;
                    }
                    &__option {
                        display: inline-block;
                        font-family: PoppinsRegular;
                    }
                    &__info {
                        font-family: PoppinsRegular;
                        text-align: center;
                        font-size: 14px;
                        color: var(--y-grey3);
                        margin-bottom: 20px;
                    }
                
                    &__description {
                        color: var(--y-grey3);
                        font-family: PoppinsRegular;
                        text-align: center;
                        font-size: 14px;
                        margin: 0 auto;
        
                        &--grey{
                            color: var(--grey4);
                            text-align: center;
                        }
                    }
                    &__details {
                        color: var(--y-grey3);
                        font-family: PoppinsRegular;
                        font-size: 14px;
                        margin: 10px auto;
                        text-align: left;
                        padding-right: 20px;
                    }
                    &__footer {
                        margin-top: 20px;
                        padding: 20px;
                        display: flex;
                        margin-top: auto;
                        span {
                            color: white;
                            text-transform: uppercase;
                            background-color: var(--y-green1);
                            cursor: pointer;
                            font-family: PoppinsSemiBold;;
                            padding: 10px 20px;
                            display: inline-block;
                            width: 100%;
                            text-align: center;
                            border-radius: 5px;
                        }
                        
                    }
                    @media only screen and (max-width: 590px) {
                        flex-basis: 100%;
                        margin: 10px auto;
                      }
                }
                @media only screen and (max-width: 590px) {
                    padding-top: 0!important;
                  }
            }
        
        }
    }
}


.border-bottom {
    border-bottom: solid 1px #979797;
    padding-bottom: 10px;
}