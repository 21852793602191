.std-wrapper {
    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    position: relative;
    .inner {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        .std-flex {
            display: flex;
            width: 100%;
            padding: 40px 20px;
           
            .std-aside {
                flex: 1;
                font-family: PoppinsRegular;
                .std-breadcrumb {
                    color: rgba(50, 72, 95, 0.4);
                    font-size: 14px;
                    margin-bottom: 20px;
                }   
                .std-title {
                    font-size: 21px;
                    color: var(--y-grey3);
                    font-family: PoppinsSemiBold;
                    margin-bottom: 5px;
                    
                }
                .std-subtitle {
                    font-size: 14px;
                    color: rgba(50, 72, 95, 0.4);
                }
           
            }

            .std-content {
                flex: 1;
                padding-left: 5vw;
             
                @media only screen and (max-width: 920px) {
                    display: none;
                 }
            }

            @media only screen and (max-width: 720px) {
                padding: 20px;
            }
        }
    }

}

.std-wrapper-image {
    margin-top: 20px;
    width: 100%;
    padding-top: 68%;
    position: relative;
    overflow: hidden;
    background-color: white;
    .std-wrapper-image-layout {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            
        }
    }
    
}

.std-wrapper-video {
    margin-top: 20px;
    width: 100%;
    padding-top: 56.25%;//16:9
    position: relative;
    overflow: hidden;
    background-color: white;
    .std-wrapper-video-layout {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
    
}

.std-actions {
    max-width: 270px;
    .std-prices {
        font-family: PoppinsSemiBold;
        color: var(--y-grey3);
        font-size: 18px;
    }
    .std-btn {
        
        @media only screen and (max-width: 720px) {
            font-size: 12px;
        }
        @media only screen and (min-width: 721px) {
            font-size: 14px;
        }
        &.added {
            background-color: white;
            border: solid 1px var(--y-green1);
            color: var(--y-green1);
            i {
                margin-right: 10px;
                &:before {
                    color:  var(--y-green1);
                }
            }
        }
    }

    @media only screen and (max-width: 720px) {
        max-width: 100%;

    }

}


.rp-wrapper {
    margin-top: 40px;
    padding: 0 20px;
    overflow-x: hidden;
    .rp-header{
        padding-top: 10px;
        border-top: solid 1px #ececec;
        .rp-title {
            font-family: PoppinsSemiBold;
            font-size: 14px;
            color: var(--y-grey3);
        }
    }
    .rp-slider {
        padding: 20px 50px;
        
        @media only screen and (max-width: 720px) {
            padding: 20px 10px 10px 0px;
            margin-top: 0;
            .slick-list {
                overflow: visible;
            }
        }
    }
    @media only screen and (max-width: 720px) {
        margin-top: 0;
    }
}
