.usage-guide-wrapper {
    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    position: relative;
    .inner{
        max-width: 1440px;
        margin: 0 auto;
        padding: 32px 20px;
        h1{
            font-family: RobotoBold;
        }
        p{
            font-family: RobotoRegular;
            color: var(--grey3);
            font-size: 14px;
        }

        .video-guide-wrapper {
             max-width: 640px;
             margin: 0 auto;
             padding: 32px 0;
            .v-ratio{
                width: 100%;
                padding-top: 56.25%;
                position: relative;
                
                .iframe-content {
                    position:  absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    
                }
            }
            
        }
       
    }
}


