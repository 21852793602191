.promo-block {
    font-family: PoppinsSemiBold;
    box-shadow: 0px 5px 17px rgba(49, 66, 90, 0.25);
    &__header {
        position: relative;
        padding-top: 50px;
    }

    &__banner {

        position: relative;
        top: -50px;
        left: 0;
        height: auto;
        background-color: var(--y-grey3);
        width: calc(100% + 20px);
        padding: 10px;
        color: white;
        text-align: center;
        margin-left: -10px;
        font-size: 20px;
        line-height: 27px;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            border-left: 10px solid transparent;
            border-top: 10px solid var(--y-grey3);
            bottom: -10px;
        }
        &:after {
            content: "";
            position: absolute;
            right: 0;
            border-right: 10px solid transparent;
            border-top: 10px solid var(--y-grey3);
            bottom: -10px;
        }

        @media only screen and (max-width:420px) {
            font-size: 18px;
            line-height: 25px;
            
         }
         @media only screen and (max-width:341px) {
            font-size: 16px;
            line-height: 22px;
            
         }
    }

    &__meta-block {
        padding: 0 20px;
        position: relative;
        top: -25px;
    }
    &__title {
        font-size: 30px;
        line-height: 40px;
        color: var(--y-grey3);
        margin-bottom: 20px;
        &--green {
            color: var(--y-green1);
            font-size: 16px;
            line-height: 20px;
        }
        @media only screen and (max-width:420px) {
            font-size: 24px;
            line-height: 34px;
            
         }
    }
    &__price {
        font-size: 42px;
        line-height: 40px;
        color: var(--y-grey3);
        text-align: center;
        .year {
            font-size: 16px;
        }
        .lined {
            text-decoration: line-through;
        }
        &--sub {
            font-size: 16px;
            color: var(--grey4);
            
        }
        &--green {
            color: var(--y-green1);
        }

        
    }

    &__body {
        padding: 10px 20px;
        font-family: PoppinsRegular;
    }

    &__footer {

        padding: 20px;
    }

}