.submenu-wrapper {
    height: 65px;
    box-shadow: 0px -3px -3px rgba(0, 0, 0, 0.3);
    display: flex;
    width: 100%;
    padding: 10px 40px;
    background-color: white;
    z-index: 10;
    position: relative;
    border-top: 1px solid #cdcdcd;
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,.05);
    .inner {
        flex: 1;
        max-width: 600px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            i {
                font-size: 24px;
                &:before{
                    color: #a6a6a6;
                }
                @media only screen and (max-width:420px) {
                    font-size: 20px;
                    
                }
                
            }
            &.active {
                i{
                    &:before{
                        color: var(--y-grey3)!important;
                    }
                }
                span {
                    font-family: PoppinsBold;
                    color: var(--y-grey3)!important;
                }
            }
            &.hasNotify {

                &:before{
                    content: "";
                    background-color: #e20d0d;
                    position: absolute;
                    top: 0;
                    right: 7px;
                    width: 33%;
                    padding-top: calc(1/1 * 33%);
                    border-radius: 50%;
                }

            }
            span {
                font-family: PoppinsRegular;
                color: #a6a6a6;
                font-size: 12px;
                text-align: center;
                text-transform: capitalize;
                @media only screen and (max-width:420px) {
                    font-size: 10px;
                    
                }
            }
        }
    }

    @media only screen and (min-width:921px) {
        display: none;
        
    }

    @media only screen and (max-width:420px) {
        padding: 10px 20px;
        
    }
}