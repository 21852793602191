  :root {
    --item-per-row-guide : 4;
    --item-per-row : 3;
    --item-per-row-preview: 4;
    --challenge-margin-left: 113px;
    --nav-height: 50px;
    --extra-nav-height : 112px;
    --subnav-height: 80px;
    --sidebar-width: 60px;
    --sidebar-width-practice: 80px;
    --sidebar-left-practice: 80px;
    --sidebar-left: 60px;
    --white: white;
    --grey1: #4F4F4F;
    --grey2: #4F4F4F;
    --grey3: #828282;
    --grey4: #BDBDBD;
    --grey5: #E0E0E0;
    --grey6: #F2F2F2;
    --pink: #FB4081;
    --blue1: #242F56;
    --blue2: #4269B0;
    --blue3: #2D9CDB;
    --blue4: #50E3C2;
    --blue5: #33BDC2;
    --blue6: #4A90E2;
    --blue7: #16559F;
    --blue8: #022651;
    --blue9: #4063DF;
    --blue10: #394ABC;
    --orange1: #E2684B;
    --red1: #DB0000;
    --red2: #EF4D4D;
    --red3: #FFA0A0;
    --red4: #FF325D;
    --yellow: #FFB53F;
    --y-grey1:#99B0BC;
    --y-grey2:#8CA3B0;
    --y-grey3:#31425A;
    --y-grey4:#E9ECED;
    --y-grey5:#393939;
    --y-grey6:#9E9E9E;
    --y-grey7:#31425A;
    --y-grey8:#F1F6F8;
    --y-grey9:#838E9C;
    --y-grey10:#EDF1F2;
    --y-grey11:#505865;
    --y-grey12:#CFDEE2;
    --y-grey13:#B6B6B6;
    --y-grey14:#C4C4C4;
    --y-grey15:#615F5F;
    --y-grey16:#AFADAD;
    --y-grey17:#E1E8E6;
    --y-grey18:#E8EDEC;
    --y-green1:#1FD5B9;
    --y-green2:#06f9d4;
    --y-blue1:#1A367C;
    --y-blue2:#31435b;
    --y-red1: #DB4A38;
    --y-color-shadow: #1C2C43;

    --primary-colors-blue-4-30-2: #d7e8ff;
    --primary-colors-blue-4: #82d4f3;

    //variables after make an user guide
    //brands colors
    --brand-primary: #30425a;
    --brand-primary-light:#98a3af;
    --brand-primary-extra-light: #ccd1d7;
    --brand-primary-extra-light-secondary: #F2F2F2;
    --brand-secondary:#1fd5b9;
    --brand-secondary-rgb: 31, 213, 185;
    --brand-secondary-dark:#1bb89f;
    --brand-terciary: #27948F;
    //neutral-colors 
    --brand-black: #000000;
    --brand-white: #ffffff;
    --dark-grey: #3d3d3d;
    --dark-grey-2: #686c71;
    --dark-grey-3: #84898f;
    --dark-grey-4:#eaecef;
    --light-grey-1: #b2bac4;
    --light-grey-2: #f8f8f8;
    --light-grey-3:#F1F4F4;
    --light-grey-4:#F3F3F3;
    
    //utility and overlay colors
    --red-color:#FF6767;
    --brand-error: #e20d0d;
    --red-color-secondary:#EB5757;
    --brand-positive: #2dcc70;
    --brand-alarm: #ffcf00;
    --blue-link: #3598d4;

    --border-color: #cbcbcb;
    --border-google-color: #4285F4;
    --border-facebook-color:#143482;
    --font-primary: PoppinsBold;
    --font-secondary: PoppinsRegular;

    --drawer-width: 250px;
    --profile-menu-width: 250px;

    --padding-large-y: 32px;
    --padding-regular-y: 20px;
    
    @media only screen and (max-width:920px) {
      --nav-height: 65px;
      --extra-nav-height: 80px;
    }

    @media only screen and (max-width: 920px) {
      --sidebar-left: 0;
      --sidebar-left-practice: 0;

   }
    
}