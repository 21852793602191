
.ui {
    &.modals {
        .welcome-modal {
            max-width: 420px;
            background: white!important;
            .top-header-block {
                &.header { 
                    border-bottom: none!important;
                    text-align: right;
                    padding-bottom:1.25rem!important;
                    background: transparent!important;
                    i {
                        font-size: 20px;
                        &::before {
                            color: var(--y-grey3);
                        }
                    }
                    @media only screen and (max-width: 590px) {
                        padding:10px 10px 0 10px!important;
                      }
                }
            }
            .content {
                padding-top: 0!important;
                background: transparent!important;
                .description-block {
                    text-align: center;
                    .description-title {
                        font-family: PoppinsBold!important;
                        color: var(--y-grey3);
                        margin: 0;
                        font-size: 18px;
                        &:first-child{
                            margin-bottom: 10px;
                        }
                    }
                }

                .wrapper-info-btn {
                    margin: 15px auto;
                    text-align: center;
                    max-width: 220px;
                    
                }
                

                .welcome-card {
                    max-width: 259px;
                    margin: 20px auto;
                    background-color: white;
                    overflow: hidden;
                    border-radius: 10px;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    &__header {
                        background: rgb(49,67,91);
                        background: -moz-linear-gradient(90deg, rgba(49,67,91,1) 0%, rgba(31,213,185,1) 100%);
                        background: -webkit-linear-gradient(90deg, rgba(49,67,91,1) 0%, rgba(31,213,185,1) 100%);
                        background: linear-gradient(90deg, rgba(49,67,91,1) 0%, rgba(31,213,185,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#31435b",endColorstr="#1fd5b9",GradientType=1);
                        height: 50px;
                        padding: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                    }
                    &__title {
                        color: #fff;
                        font-family: PoppinsSemiBold;
                        font-size: 16px;
                        text-align: center;
                        text-transform: uppercase;
                    }
                
                    &__body {
                        padding: 20px;
                        
                    }
                
                    &__description {
                        color: var(--y-grey3);
                        font-family: PoppinsRegular;
                        text-align: left;
                        font-size: 14px;
                        margin-bottom: 10px;
                        &--grey{
                            color: var(--grey4);
                            text-align: center;
                        }
                    }
                    &__details {
                        color: var(--y-grey3);
                        font-family: PoppinsRegular;
                        font-size: 14px;
                        margin: 10px auto;
                        text-align: left;
                        padding-right: 20px;
                    }
                    &__counter {
                        text-align: center;
                        letter-spacing: 1px;
                    }   
                
                    &__footer {
                        margin-top: 20px;
                        padding: 20px;
                        display: flex;
                        justify-content: flex-end;
                        
                    }
                    @media only screen and (max-width: 590px) {

                        margin: 10px auto 20px auto;
                      }
                }

            }

        }

    }
}