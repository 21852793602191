.wrapper-slick-grid {
    position: relative;
    padding: 0 24px;

    @media only screen and (max-width: 420px) {
        padding: 0;
    }

    .item-slide {
        padding: 10px;
        cursor: pointer;
        height: 100%;

        .card-content {
            border-radius: 5px;
            border: solid 1px var(--border-color);
            overflow: hidden;
            background-color: white;
            height: 100%;
            position: relative;

            .card-indicator {
                position: absolute;
                top: 8px;
                left: 8px;
                background-color: var(--brand-secondary);
                padding: 5px;
                z-index: 2;
                display: inline-block;
                border-radius: 5px;
            }

            &:hover {
                background-color: #f8f8f8;
            }

            .card-body {
                .card-title {
                    font-family: PoppinsSemiBold;
                    font-size: 14px;
                    color: var(--brand-primary);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding: 12px 8px;
                    margin: 0;
                }

                .card-footer {
                    border-top: solid 1px var(--border-color);
                    padding: 10px 8px;

                    .footer-item {
                        background-color: var(--dark-grey-4);
                        display: inline-block;
                        border-radius: 5px;
                        padding: 2px 10px;
                        margin: 5px;
                    }
                }
            }

            .base-layout {
                width: 100%;
                padding-top: 56.25%;
                position: relative;
                overflow: hidden;

                .base-inner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;

                    img {
                        bottom: 0px;
                        display: block;
                        height: 100%;
                        left: 0px;
                        object-fit: cover;
                        opacity: 1;
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        width: 100%;
                    }
                }
            }

            .bloqued-layer {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                background: rgba(0,0,0,.45);
                text-align: center;
                visibility: hidden;

                i {
                    font-size: 100px;

                    &::before {
                        color: var(--y-green1);
                    }
                }

                span {
                    font-family: PoppinsBold;
                    font-size: 16px;
                    color: var(--y-green2) !important;
                    text-transform: capitalize;
                }

                &.visibled {
                    visibility: visible;
                }
            }
        }
    }

    .slick-slider {
        margin: 10px 0;

        .slick-list {
            .slick-track {
                margin-left: 0;
                margin-right: 0;
                display: flex;
                align-items: stretch;
            }

            .slick-slide {
                opacity: 0.5;
                height: auto;

                > div {
                    height: 100%;
                }

                &.slick-active {
                    opacity: 1;
                }
            }
        }
    }
}

.slick-custom-btn {
    cursor: pointer;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    opacity: 1;
    position: absolute;
    top: 0px;
    transform: translateX(-100%);
    width: 34px;
    z-index: 1;
    background: transparent;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    padding: 0px;
    transition: opacity 0.2s ease 0s;
    align-items: center;

    &.prev-a {
        left: 0;
    }

    &.next-a {
        right: 0;
        transform: translateX(100%);
    }

    &.slick-disabled {
        display: none;
    }
}