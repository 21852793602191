.extra-nav {
    display: flex;
    flex-direction: row;
    padding: 20px 30px;
    z-index:1;
    position: relative;
    .icon {
        width: 50px;
        height: 50px;
        background-image: url('../../../assets/img/yogabot_icon.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        @media only screen and (max-width: 520px) {
            width: 40px;
            height: 40px;
        }
    }

    &.register {
        border-bottom: solid 3px #1F2E44;
        background: transparent;
        justify-content: space-between;
        align-items: center;
        height: var(--extra-nav-height);
        .icon{
            width: 280px;
            height: 57px;
            background-image: url('../../../assets/img/logo_large.svg');
            @media only screen and (max-width: 620px) {
                background-image: url('../../../assets/img/logo_mobile.svg');
                width: 61px;
                height: 61px;
                
            }
        }

        @media only screen and (max-width: 420px) {
            border-width: 0;
        }
    }

    @media only screen and (max-width: 520px) {
        // height: 60px;
        padding: 10px;
        box-sizing: border-box;
    }
}
