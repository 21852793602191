

.hero-section {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    height: 0;
    padding-top: calc(9/25 * 100%);
    overflow: hidden;
    margin-top: 32px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    .background-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    
        &:after {
            content: "";
            background: -moz-linear-gradient(top,  rgba(255,255,255,0.3) 0%, rgba(255,255,255,1) 87%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(255,255,255,0.3) 0%,rgba(255,255,255,1) 87%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(255,255,255,0.3) 0%,rgba(255,255,255,1) 87%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
        }
    }

    @media only screen and (max-width:720px) {
        margin-top: 0;
        border-radius: 0;
     }

}

.preview-iframe {

    width: 100%;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.9);
    .preview-expanded-16-9 {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        overflow: hidden;
        display: block;
        right: 0;
        padding: 5vw;
    }

}

.spacing {

    display: block;
    position: relative;
    z-index: 2;
    width: 100%;
    padding-top: 23%;

}

.close-preview {
    position: absolute;
    top: 25px;
    right: 4%;
    z-index: 999999;
    cursor: pointer;
        &::before{
            color:white;
        }
    
}

.master-content {

    position: relative;
    z-index: 2;    
    width: 100%;
    max-width: 1350px;
    margin: 32px auto 0 auto;
    
    .master-ctas {
        padding: 20px 0 40px 0;
        @media only screen and (max-width:720px) {
            padding: 20px 0;
         }
    }
    .master-meta {
        padding: 20px 0;
        display: flex;
        width: 100%;
        .master-description {
            flex-grow: 2;
            flex-shrink: 1;
            flex-basis: 0;
            margin-right: 50px;
        }
        .master-related {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
            .related-items {
                display: flex;
                flex-wrap: wrap;
                margin-left: -5px;
                .related-item {
                    background-color: rgba(48, 66, 90, 0.1);
                    padding: 10px;
                    color: rgba(48, 66, 90, 0.6);
                    font-size: 14px;
                    margin: 5px;
                    border-radius: 3px;
                    font-family: PoppinsRegular;
                }
            }
            @media only screen and (max-width:720px) {
                margin-top: 20px;
             }
        }
        @media only screen and (max-width:720px) {
            display: block;
         }
    }
    .master-targets {
        padding: 20px 0;
        .targets-wrapper {
            display: flex;
            width: 100%;
            gap: 2rem;
            .targets-items {
                flex-grow: 2;
                flex-shrink: 1;
                flex-basis: 0;
                margin-right: 50px;
                .target-item {
                    border-bottom: solid 1px  var(--border-color);
                    padding: 8px 0;
                    &:last-child{
                        border: none;
                    }
                }
            }
            .target-spacer {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0;
                @media only screen and (max-width:720px) {
                    display: none;
                 }
            }
        }
    }
    .master-grid-wrapper {
        padding: 40px 0 5vw 0;
        .master-grid{
            display: flex;
            flex-direction: column;
            .master-grid-item {
                display: flex;
                border: solid 1px  rgba(192, 198, 205, 0.4);
                padding: 40px 30px;
                border-radius: 3px;
                margin-bottom: 15px;
                background-color: #fff;
                cursor: pointer;
                .item-number {
                    flex-shrink: 0;
                    flex-grow: 0;
                    flex-basis: auto;
                    margin: 0 30px 0 0px;
                    align-self: center;
                    @media only screen and (max-width:920px) {
                        margin: 20px 0 ;
                     }
                }
                .item-cover {
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: 0;
                    margin: 0 20px;
                    align-self: center;
                    cursor: pointer;
                    img {
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
                        max-width: 320px;
                        @media only screen and (max-width:460px) {
                            width: 100%;
                         }
                    }
                    @media only screen and (max-width:920px) {
                        
                        margin: 20px 0 ;
                     }
                }
                .item-content {
                    flex-grow: 3;
                    flex-shrink: 1;
                    flex-basis: 0;
                    margin: 0 20px;
                    .item-title {
                        
                        margin-bottom: 10px;
                    }
                   

                    @media only screen and (max-width:920px) {
                        
                        margin: 20px 0 ;
                     }
                }
                @media only screen and (max-width:920px) {
                   display: block;
                   padding: 10px 32px;
                }
                &:hover {
                    background-color: #f8f8f9;
                }
                &:active {
                    background-color: #cacaca;
                }
            }
        }
    }
}


.display-2 {
    font-size: 28px;
    font-family: PoppinsBold;
    color: var(--y-grey3);
    @media only screen and (max-width: 420px) {
        font-size: 26px;;
    }
}


.display-1 {
    font-size: 34px;
    font-family: PoppinsRegular;
    color: var(--y-grey3);
    @media only screen and (max-width: 420px) {
        font-size: 32px;
    }
}

.display-subtitle-2 {
    font-size: 14px;
    font-family: PoppinsBold;
    color: var(--y-grey3);
    line-height: 18px;
    @media only screen and (max-width: 420px) {
        font-size: 12px;
    }
}

.display-subtitle-1 {
    font-size: 16px;
    font-family: PoppinsRegular;
    color: var(--y-grey3);
    line-height: normal;
    @media only screen and (max-width: 420px) {
        font-size: 14px;
    }
}

.display-subtitle-4 {
    font-size: 14px;
    font-family: PoppinsRegular;
    color: var(--y-grey3);
    line-height: normal;
   
}

.display-subtitle-3 {
    font-size: 16px;
    font-family: PoppinsBold;
    color: var(--y-grey3);
    line-height: normal;
    letter-spacing: 0.5px;
    @media only screen and (max-width: 420px) {
        font-size: 14px;
    }
}

.display-title {
    font-size: 20px;
    font-family: PoppinsBold;
    color: var(--y-grey3);
    @media only screen and (max-width: 420px) {
        font-size: 18px;
    }
}
.display-caption {
    font-size: 12px;
    font-family: PoppinsRegular;
    color: var(--y-grey3);
}

.y-message {
    padding: 20px 0;
}

.footer-spacer {
    margin-bottom: 5vw;
}