@media (max-width: 500px) {
    button.ui.button.addInjuryButton {
        padding-right: 18px;
        padding-left: 18px;
    }
}

.injuryList {
    .ui.list > .item > i.icon {
        display: none;
    }
}