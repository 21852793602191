.wrapper-practice-result{

    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    position: relative;
    .inner {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100%;
        flex-direction: column;
        font-size: 16px;

        div {
            text-align: center;
            padding: 30px 10px;
        }
        p{
            color: var(--y-grey6);
            font-family: PoppinsRegular;
            
            font-size: 1.2em;
        }

        .f-icon-info-circled {
            cursor: pointer;
            &:before{
                color: var(--y-green1);
            }
           
        }

        img {
            max-width: 100%;
            padding-bottom: 50px;
            margin: 0 auto;
        }

        @media only screen and (max-width: 920px) {
            font-size: 15px;
            padding-top: 48px;
        }

        @media only screen and (max-width: 620px) {
            font-size: 14px;
        }

        @media only screen and (max-width: 420px) {
            font-size: 13px;
        }
    }
}