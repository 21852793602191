
.ui {
    &.modals {
        .send-message-modal {
            max-width: 420px;
            background: white!important;
            .top-header-block {
                &.header { 
                    border-bottom: none!important;
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 1.25rem!important;
                    background: transparent!important;
                    i {
                        font-size: 20px;
                        &::before {
                            color: var(--y-grey3);
                        }
                    }
                    @media only screen and (max-width: 590px) {
                        padding:10px 10px 0 10px!important;
                      }
                }
            }
            .content {
                padding-top: 0!important;
                background: transparent!important;
                .description-block {
                    .description-title {
                        font-family: PoppinsBold!important;
                        color: var(--y-grey3);
                        margin: 0;
                        font-size: 18px;
                        &:first-child{
                            margin-bottom: 10px;
                        }
                    }
                }

                .wrapper-info-btn {
                    padding-top: 10px;

                    button{
                        margin-left: 0;
                    }
                }

            }

        }

    }
}