.profile-wrapper {
    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    margin-left: var(--sidebar-left);
    position: relative;
    .inner{
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        min-height: 100%;
        .inner-suscription {
            padding: 64px 32px;
            .f-icon-descarga {
                &::before {
                    color: var(--y-green1)
                }
            }
            .title, .subtitle {
                font-family: PoppinsRegular;
                color:var(--y-grey3);
                text-align: center;
            }
            .title {
                font-size: 36px;
                span{
                    color:var(--y-green1);
                }
                @media only screen and (max-width:520px) {
                    font-size: 24px;
                    text-align: left;
                    p {
                        line-height: 26px;
                    }
                    
                }
            }
            .subtitle {
                font-size: 14px;
                @media only screen and (max-width:520px) {
                    margin: 15px 0;
                    text-align: left;
                }
            }
            .icon-wallet {
                margin-right: 10px;
                &::before {
                    color: var(--y-green1);
                    font-size: 20px;
                }
            }
            .premium-cta-wrapper {
                padding: 40px 30px;
                text-align: center;
                span {
                    display: inline-block;
                    background-color: var(--y-green1);
                    text-transform: uppercase;
                    color: white;
                    padding: 10px 20px;
                    font-family: PoppinsSemiBold;
                    font-size: 24px;
                    cursor: pointer;
                }
            }
            @media only screen and (max-width:520px) {
                padding: 32px;
            }
        }

        @media only screen and (max-width:660px) {
            button {
                font-size: 18px;
            }
            
         }
      
    }

}




.promo-wrapper {
    padding: 32px;

    .promo-header {

        font-family: PoppinsRegular;
        color: var(--y-grey3);
        padding: 32px;
        &__title {
            
            font-size: 36px;
            text-align: center;
            margin: 10px 0;
            @media only screen and (max-width:1200px) {
                font-size: 32px;
                
             }
             @media only screen and (max-width:590px) {
                
                font-size: 28px;
            }
        }
    
        &__subtitle {
            
            font-size: 28px;
            text-align: center;
            @media only screen and (max-width:1200px) {
                font-size: 22px;
                
             }
             @media only screen and (max-width:590px) {
                
                font-size: 20px;
            }
            
        }
        &__countdown{
            color: var(--y-green1);
            font-size: 22px;
            text-align: center;
            @media only screen and (max-width:1200px) {
                font-size: 20px;
                
             }
        }
    
        @media only screen and (max-width:590px) {
            padding: 0;
           
        }

    }
    .promo-counter {
        padding: 30px 0;
    }

    .promo-btn {
        padding: 30px 0;
        text-align: center;
        span {
            background-color:var(--y-green1);
            padding: 20px 40px;
            color: white;
            display: inline-block;
            font-family: PoppinsSemibold;
            text-transform: uppercase;
            font-size: 24px;
            line-height: 30px;
            box-shadow: 0px 5px 17px rgba(49, 66, 90, 0.25);
            cursor: pointer;
            @media only screen and (max-width:590px) {
                font-size: 20px;
                line-height: 26px;
            }
        }
        @media only screen and (max-width:590px) {
            padding: 20px 0;
            
        }
    }
    .promo-body {
        padding: 30px 0;

        p {
            color: var(--grey3);
            font-size: 16px;
            font-family: PoppinsRegular; 
        }
        @media only screen and (max-width:590px) {
                
            padding: 10px 0;
        }
    }

    @media only screen and (max-width:590px) {
        padding: 20px;
    }
}

.payment-data-title {
    text-transform: uppercase;
    text-align: left;
    font-family: BebasNeueBold;
    padding: 15px 10px;
    font-size: 29px;
    background: var(--y-grey18);
    @media only screen and (max-width:820px) {
        font-size: 26px;
        line-height: 26px;
    }
}


