.payments-wrapper {
    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    .inner {
        width: 100%;
        margin: 0 auto;
        padding: 60px 20px;
        flex: 1 0 auto;
        h1 {
            color: var(--y-grey16);
            text-transform: uppercase;
            text-align: center;
            @media only screen and (max-width: 420px) {
                font-size: 20px;
            }
        }
        .stripe-block {
            max-width: 500px;
            width: 100%;
            margin: 0 auto;

            .blocks-separator {
                box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
                padding: 20px;
                border-radius: 4px;
                margin-bottom: 20px;
                .hr-separator {
                    color: var(--y-green1);
                    margin-bottom: 20px;

                }
                .title {
                    font-family: PoppinsSemiBold;
                    color: var(--y-grey16);
                }
                .ui.input.stripe-coupon-code input::placeholder {
                    color: grey;
                    font-size: 1em;
                }
            }
            .infoPayMent {
                background-color: var(--y-grey3);
                padding: 10px;
                margin-top: 40px;

                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                i {
                    &::before {
                        font-size: 30px;
                        color: white;
                    }
                }
                .pay-info {
                    .title-price {
                        display: block;
                    }
                    span{
                        color: white;
                        font-family: PoppinsRegular;
                        text-align: right;
                        margin-bottom: 5px;
                    }
                    .info-price {
                        font-size: 24px;
                        font-family: PoppinsSemiBold;

                    }
                }
            }
            button {
                white-space: nowrap;
                border: 0;
                outline: 0;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                padding: 0 14px;
                box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
                color: #fff;
                border-radius: 4px;
                font-size: 15px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.025em;
                background-color: var(--y-green1);
                text-decoration: none;
                -webkit-transition: all 150ms ease;
                transition: all 150ms ease;
                width: 100%;
                &:hover{

                    color: #fff;
                    cursor: pointer;
                    background-color: var(--y-grey3);
                    transform: translateY(-1px);
                    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);

                }
                &:disabled {
                    background-color: var(--y-grey16);
                    opacity: 0.5;
                    &:hover {
                        background-color: var(--y-grey16);
                        opacity: 0.5;
                    }
                }
            }

            input, .StripeElement {
                display: block;
                margin: 10px 0 20px 0;
                max-width: 500px;
                padding: 10px 14px;
                font-size: 16px;
                font-family: PoppinsRegular;
                box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
                rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
                border: 0;
                outline: 0;
                border-radius: 4px;
                background: white;
            }

            input::placeholder {
                color: rgba(0,0,0,.87);
            }
            input:focus {
                box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
                rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
                -webkit-transition: all 150ms ease;
                transition: all 150ms ease;
            }
            .StripeElement--focus {
                box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
                rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
                -webkit-transition: all 150ms ease;
                transition: all 150ms ease;
            }
            .StripeElement {
                &.PaymentRequestButton {
                    height: 40px;
                    padding: 0;
                }

            }
        }

    }

}

.custom-select-payment {
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px!important;

    border-radius: 4px!important;
    border: 0!important;
    outline: 0;
    padding: 10px 14px!important;
    font-size: 1rem!important;
    font-family: PoppinsRegular;
    display: block;
    flex: 1;
    @media only screen and (max-width: 340px) {
        min-width: auto!important;
    }

}

.correct-payment-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    .content-payment {
        max-width: 500px;
        p {
            color: var(--y-grey3);
            font-family: PoppinsRegular;
            font-size: 24px;
            margin-top: 40px;
        }
    }
}

.cl-12 {
    width: 75%!important;
    padding-left: .5em;
    padding-right: .5em;
}
.cl-4 {
    width: 25%!important;
    padding-left: .5em;
    padding-right: .5em;
}

.one-row-field {
    display: flex;
    flex-direction: row;
    margin: 0 -.5em 1em;
}

.wrapper-row-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    label {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 25%;
        margin: 0 .9rem .28571429rem 0;
        color: rgba(0,0,0,.87);
        font-size: .92857143em;
        font-weight: 700;
        text-transform: none;
    }
    .select-payment-wrapper {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        display: flex;

    }
}