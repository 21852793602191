.st-grid {
    display: flex;
    flex-wrap: wrap;
}

//arrow buttons for slider studio asanas

.slick-custom-btn-primary {
    cursor: pointer;
    display: flex;
    justify-content: center;
    opacity: 1;
    position: absolute;
    height: 40px;
    top: calc(50% - 40px);
    transform: translateX(-100%);
    width: 40px;
    z-index: 1;
    background: white;
    border:solid 1px #c1c1c1;
    padding: 5px;
    transition: opacity 0.2s ease 0s;
    align-items: center;
    border-radius: 3px;
    &.next-a {
        right: -5px;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    &.prev-a {
        left: -5px;
    }

    &.slick-disabled {
        display: none;
    }
}