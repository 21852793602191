.landing-hero {
    min-height: 100%;
    display: flex;
    background-image: linear-gradient(180deg, rgba(255,255,255,0.01193981010372902) 0%, rgba(255,255,255,0.5385504543614321) 20%, rgba(255,255,255,0.5357493339132529) 80%, rgba(255,255,255,0) 100%), url('../../../assets/img/landing/r1_large.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    justify-content: flex-end;
    align-items: center;
    max-height: 1080px;
    border-bottom: solid 12px var(--y-grey3); 
    .logo-float {
        text-align: center;
        left: 39px;
        position: absolute;
        top: 28px;
        img {
            max-width: 300px;
        }
        @media only screen and (max-width: 920px) {
            display: none
        }
    }

    .icon-landing {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 32px;
        left: 39px;
        background-image: url('../../../assets/img/yogabot_icon.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        display: none;

        @media only screen and (max-width: 920px) {
            display: block
        }
        @media only screen and (max-width: 520px) {
            width: 45px;
            height: 45px;
            top: 25px;
            left: 20px;
        }

    }

    .wraper-header-btns {

        position: absolute;
        top: 40px;
        right: 50px;

        @media only screen and (max-width: 520px) {
            top: 28px;
            right: 20px;
        }
        @media only screen and (max-width: 420px) {
            top: 10px;
            display: flex;
            flex-direction: column;
            button{
                &:first-child{
                    margin-bottom: 5px;
                }
               
            }
        }
    }


    .hero-content {
        padding-top: 90px;
        p {
            margin: 0;
        }
        .title {
            font-family: BebasNeueBold;
            font-size: 60px;
            color:var(--y-grey3);
            text-transform: uppercase;
            text-align: center;
            span {
                color: white
            }
            
            @media only screen and (max-width: 1280px) {
                font-size: 42px;
                
            }
            @media only screen and (max-width: 620px) {
                font-size: 36px;
                
            }
            @media only screen and (max-width: 420px) {
                padding: 20px 10px;
                
            }

            @media only screen and (max-width: 320px) {
                font-size: 30px;
                
            }
            
        }
        .huge-title {
            font-family: PoppinsRegular;
            font-size: 91px;
            color:var(--y-grey3);
            text-transform: uppercase;
            line-height: 106%;
            text-align: center;
            @media only screen and (max-width: 1280px) {
                font-size: 60px;
                
            }
            @media only screen and (max-width: 620px) {
                font-size: 46px;
            }
            @media only screen and (max-width: 420px) {
                padding: 0 10px;
                
            }
            @media only screen and (max-width: 320px) {
                font-size: 30px;
                
            }
        }
        .medium-title {
            font-family: PoppinsRegular;
            font-size: 48px;
            color:var(--y-grey3);
            text-transform: uppercase;
            line-height: 74%;
            text-align: center;
            margin-top: 20px;
            @media only screen and (max-width: 1280px) {
                font-size: 28px;
                
            }
            @media only screen and (max-width: 620px) {
                font-size: 22px;
            }
            @media only screen and (max-width: 420px) {
                padding: 0 10px;
                
            }
            @media only screen and (max-width: 320px) {
                font-size: 18px;
                
            }
        }
        .block-adver {
            
            font-family: PoppinsRegular;
            font-size: 52px;
            line-height: 108%;
            color: white;
            text-transform: uppercase;
            background: url('../../../assets/img/degradado.png') no-repeat;
            background-position:right;
            background-color:var(--y-grey3);
            padding: 0 120px 0 80px;
            border-bottom: solid 6px black;
            margin-top: 30px;
            clip-path: polygon(100% 0, 0 0, 5% 100%, 100% 100%);
            
            @media only screen and (max-width: 1280px) {
                font-size: 38px;
                text-align: center;
                clip-path: none;
                padding: 5px 20px;
            }
            
            @media only screen and (max-width: 620px) {
                font-size: 24px;
                border-width: 0;
            }
            @media only screen and (max-width: 320px) {
                font-size: 20px;
                padding: 10px;
                
                
            }
        }
        .title-description {
            font-family: PoppinsRegular;
            font-size: 40px;
            text-align: center;
            margin-top: 30px;
            text-transform: uppercase;
            color:var(--y-grey3);
            span{
                color: var(--y-green1);
            }
            @media only screen and (max-width: 1280px) {
                font-size: 28px;
                
            }
            @media only screen and (max-width: 620px) {
                font-size: 22px;
            }
            @media only screen and (max-width: 420px) {
                padding: 0 10px;
                
            }

            @media only screen and (max-width: 320px) {
                font-size: 18px;
                padding: 10px;
                margin-top: 20px;
            }
        }
        .btn-start {
            text-align: center;
            margin-top: 60px;
            span {
                cursor: pointer;
                padding: 10px 20px;
                color: white;
                border-radius: 24px;
                background-color: var(--y-grey9);
                text-transform: uppercase;
                
                font-family: PoppinsRegular;
                font-size: 24px;
                @media only screen and (max-width: 1024px) {
                    font-size: 18px;
                    
                }
                @media only screen and (max-width: 620px) {
                    font-size:16px;
                    margin-top: 30px;
                }
                @media only screen and (max-width: 320px) {
                    font-size: 14px;
                    
                }
            }
            @media only screen and (max-width: 620px) {
                margin-top: 30px;
                
            }
            
        }
        .title-sub {
            font-family: PoppinsRegular;
            color:var(--y-grey3);
            font-size: 18px;
            margin-top: 60px;
            text-align: center;
            padding: 0 20px;
            margin-right: 80px;
            margin-bottom: 40px;
            @media only screen and (max-width: 1280px) {
                font-size: 16px;
                margin-right: 0px;
            }
            @media only screen and (max-width: 420px) {
                
                margin-top: 40px;   
            }
        }
        @media only screen and (max-width: 1280px) {
            flex: 1;
             
        }
        
    }
    @media only screen and (max-width: 1024px) {
        justify-content: center;
        
    }

    @media only screen and (max-width: 920px) {
        min-height: 720px        
    }

    @media only screen and (max-width: 620px) {
        min-height: 620px         
    }

    
}

.landing-section {
    margin: 0 auto;
    max-width: 1440px;
    padding: 70px 40px;
    &.content-one {
        display: flex;
        font-family: PoppinsRegular;
        color: white;
        img {
            width: 100%;
            height: auto;
            max-width: 287px;
           
            margin: auto auto 20px auto;
            @media only screen and (max-width: 920px) {
                margin: 20px auto;
                
            }
        }
        .left{
            background-color: var(--y-grey3);
            flex: 1;
            padding: 4% 5% 2% 5%;
            position: relative;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            .title {
                font-size: 36px;
                font-family: PoppinsBold;
                margin-bottom: 30px;
                text-align: center;
                @media only screen and (max-width: 1280px) {
                    font-size: 28px;
                    
                }
                @media only screen and (max-width: 1024px) {
                    font-size: 24px;
                    
                }
                @media only screen and (max-width: 920px) {
                    font-size: 22px;
                    
                }
            }
            .description {
                text-align: left;
                font-size: 30px;
                @media only screen and (max-width: 1280px) {
                    font-size: 24px;
                    
                }
                @media only screen and (max-width: 1024px) {
                    font-size: 20px;
                    
                }
                @media only screen and (max-width: 920px) {
                    font-size: 16px;
                    
                }
            }
            .rule{
                position: absolute;
                right: 0;
                top: -5%;
                height: 110%;
                background-size: cover;
                background-position: top;
                width: 20px;
                background-image: url('../../../assets/img/ruler.png');
                
            }

            @media only screen and (max-width: 920px) {
                padding: 20px 40px 20px 20px;
                
            }
        }
        .right {
            flex: 1;
            color: var(--y-grey3);
            padding: 4% 5% 2% 5%;
            position: relative;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            .title {
                font-size: 36px;
                font-family: PoppinsBold;
                margin-bottom: 30px;
                text-align: center;
                @media only screen and (max-width: 1280px) {
                    font-size: 28px;
                    
                }
                @media only screen and (max-width: 1024px) {
                    font-size: 24px;
                    
                }
                @media only screen and (max-width: 920px) {
                    font-size: 22px;
                    
                }
            }
            .description {
                text-align: left;
                font-size: 30px;
                @media only screen and (max-width: 1280px) {
                    font-size: 24px;
                    
                }
                @media only screen and (max-width: 1024px) {
                    font-size: 20px;
                    
                }
                @media only screen and (max-width: 920px) {
                    font-size: 16px;
                    
                }

                
            }
            .sub-text-image {
                font-family: PoppinsBold;
                font-size: 48px;
                text-align: center;
                margin: 0;
                @media only screen and (max-width: 1280px) {
                    font-size: 36px;
                    
                }

                @media only screen and (max-width: 1024px) {
                    font-size: 28px;
                    
                }

                @media only screen and (max-width: 920px) {
                    font-size: 26px;
                    text-align: left;
                }

                @media only screen and (max-width: 420px) {
                    font-size: 20px;
                    
                }
            }
            @media only screen and (max-width: 920px) {
                padding: 20px 30px;
                
            }
        }
        @media only screen and (max-width: 920px) {
            flex-direction: column;
            
        }
    }
    &.content-two {
        font-family: PoppinsRegular;
        color: var(--y-grey3);
        .title {
            font-family: BebasNeueBold;
            text-align: center;
            font-size: 72px;
            span {
                color: var(--y-green1);
            }
            @media only screen and (max-width: 1280px) {
                font-size: 65px;
                
            }
            @media only screen and (max-width: 1024px) {
                font-size: 60px;
                
            }
            @media only screen and (max-width: 920px) {
                font-size: 55px;
                
            }

            @media only screen and (max-width: 420px) {
                font-size: 36px;
                
            }
        }
        .descriptions {
            font-size: 30px;
            text-align: left;
            b{
                font-family: PoppinsBold;
            }
            @media only screen and (max-width: 1280px) {
                font-size: 28px;
                
            }
            @media only screen and (max-width: 1024px) {
                font-size: 26px;
                
            }
            @media only screen and (max-width: 920px) {
                font-size: 22px;
                
            }

            @media only screen and (max-width: 420px) {
                font-size: 16px;
                
            }
        }
        .f-image {
            text-align: center;
            margin-top: 60px;
            margin-bottom: 20px;
            img {
                width: 100%;
                max-width: 1095px;
                height: auto;
            }
        }
        .box-btn {
            max-width: 761px;
            margin: 30px auto;
            &.bordering {
                border-width: 7px;
                border-style: solid;
                padding: 20px;
                border-image: linear-gradient(to bottom, var(--y-green1), rgba(0, 0, 0, 0)) 1 1%;
                position: relative;
                .box-btn-title {
                    font-family: PoppinsRegular;
                    font-size: 45px;
                    color: var(--y-grey3);
                    text-align: center;
                    text-transform: uppercase;
                    margin: 0;
                    @media only screen and (max-width:820px) {
                        font-size: 35px;
                        
                    }
                    @media only screen and (max-width:620px) {
                        font-size: 32px;
                        
                    }

                    @media only screen and (max-width:420px) {
                        font-size: 28px;
                    }
                }
                .box-btn-subtitle {
                    font-family: PoppinsRegular;
                    font-size: 24px;
                    color: var(--y-grey3);
                    text-align: center;
                    margin: 0;
                    @media only screen and (max-width:820px) {
                        font-size: 20px;
                        
                    }
                    @media only screen and (max-width:620px) {
                        font-size: 18px;
                        
                    }
                }
                .box-btn-description {
                    font-family: PoppinsRegular;
                    font-size: 30px;
                    color: var(--y-grey3);
                    text-align: center;
                    @media only screen and (max-width:820px) {
                        font-size: 26px;
                        
                    }
                    @media only screen and (max-width:620px) {
                        font-size: 24px;
                        
                    }
                }
                .comma {
                    font-family: BebasNeueRegular;
                    font-size: 330px;
                    color: #AFADAD;
                    margin: 0;
                    line-height: 296px;
                    height: 116px;
                    text-align: center;
                    @media only screen and (max-width:420px) {
                        font-size: 240px;
                        line-height: 218px;
                        height: 80px;
                    }
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 80%;
                    left: -50px;
                    width: 50px;
                    height: 50px;
                    background-image: url('../../../assets/img/flecha_1.png');
                    background-size: contain;
                    @media only screen and (max-width:950px) {
                        top: 68%;
                    }
                    @media only screen and (max-width:860px) {
                        display: none;
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 80%;;
                    right: -50px;
                    width: 50px;
                    height: 50px;
                    background-image: url('../../../assets/img/flecha_2.png');
                    background-size: contain;
                    @media only screen and (max-width:950px) {
                        top: 68%;
                    }
                    @media only screen and (max-width:860px) {
                        display: none;
                    }
                }
            }
            .box-desktop {
                &.override {
                    input {
                        min-height: 55px;
                        border: 2px solid var(--y-green1);
                        border-top-right-radius: 0 !important;
                        border-bottom-right-radius: 0 !important;
                        &::placeholder{
                            color: var(--y-grey9) !important;
                            font-family: PoppinsRegular;
                            
                            }
                        @media only screen and (max-width: 950px) {
                            border-top-right-radius: 0.28571rem !important;
                            border-bottom-right-radius: 0.28571rem !important;
                            border-right-color: var(--y-green1)!important;
                            
                        }
                        
                    }
                    button {
                        font-size: 20px!important;
                        box-shadow: none!important;
                        @media only screen and (max-width: 950px) {
                            display: none;
    
                        }
                    }
                }    
                
            }

            @media only screen and (max-width: 820px) {
                min-width: 100%;
                margin: 0 auto;
              }
        }
    }
    &.content-three {
        display: flex;
        color: var(--y-grey3);
        b{
            font-family: PoppinsBold;
        }
        .left{
            background-color: var(--y-green1);
            flex: 1;
            padding: 4% 5% 2% 5%;
            position: relative;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            .title {
                font-size: 36px;
                font-family: PoppinsBold;
                margin-bottom: 30px;
                text-align: center;
                @media only screen and (max-width: 1280px) {
                    font-size: 28px;
                    
                }
                @media only screen and (max-width: 1024px) {
                    font-size: 24px;
                    
                }
                @media only screen and (max-width: 920px) {
                    font-size: 22px;
                    
                }
            }
            .description {
                text-align: left;
                font-size: 30px;
                @media only screen and (max-width: 1280px) {
                    font-size: 24px;
                    
                }
                @media only screen and (max-width: 1024px) {
                    font-size: 20px;
                    
                }
                @media only screen and (max-width: 920px) {
                    font-size: 16px;
                    
                }
            }

            @media only screen and (max-width: 920px) {
                padding: 20px;
                
            }
        }

        .right {
            flex: 1;
            color: var(--y-grey3);
            padding: 4% 5% 2% 5%;
            position: relative;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            .title {
                font-size: 36px;
                font-family: PoppinsBold;
                margin-bottom: 30px;
                text-align: center;
                @media only screen and (max-width: 1280px) {
                    font-size: 28px;
                    
                }
                @media only screen and (max-width: 1024px) {
                    font-size: 24px;
                    
                }
                @media only screen and (max-width: 920px) {
                    font-size: 22px;
                    
                }
            }
            .description {
                text-align: left;
                font-size: 30px;
                @media only screen and (max-width: 1280px) {
                    font-size: 24px;
                    
                }
                @media only screen and (max-width: 1024px) {
                    font-size: 20px;
                    
                }
                @media only screen and (max-width: 920px) {
                    font-size: 16px;
                    
                }
            }
            .sub-text-image {
                font-family: PoppinsBold;
                font-size: 48px;
                text-align: center;
                @media only screen and (max-width: 1280px) {
                    font-size: 36px;
                    
                }
            }
            @media only screen and (max-width: 920px) {
                padding: 20px;
                
            }
        }
        @media only screen and (max-width: 920px) {
            flex-direction: column;
            
        }
    }
    &.content-four {
        color: var(--y-grey3);
        .title {
            font-family: BebasNeueBold;
            font-size: 72px;
            text-align: center;
            margin: 0;
            @media only screen and (max-width: 1280px) {
                font-size: 65px;
                
            }
            @media only screen and (max-width: 920px) {
                font-size: 55px;
                
            }
            @media only screen and (max-width: 820px) {
                font-size: 46px;
                
            }
            @media only screen and (max-width: 660px) {
                font-size: 36px;
                
            }
        }
        .block-description {
            padding: 20px;
            display: inline-block;
            background-color: var(--y-grey3);
            margin-top: 70px;
            p {
                font-family: PoppinsRegular;
                font-size: 24px;
                color: white;
                @media only screen and (max-width: 820px) {
                    font-size: 22px;
                    
                }
                @media only screen and (max-width: 620px) {
                    font-size: 20px;
                    
                }

                @media only screen and (max-width: 420px) {
                    font-size: 18px;
                    
                }
            }
            @media only screen and (max-width: 1200px) {
                display: block;
                text-align: center;
                margin-top: 200px;
                
            }

            @media only screen and (max-width:930px) {
                margin-top: 40px;
                
            }

            @media only screen and (max-width: 420px) {
                margin-top: 20px;
                
            }
        }
        .description {
            font-family: PoppinsRegular;
            text-align: center;
            font-size: 30px;
            font-weight: 600;
            @media only screen and (max-width: 1280px) {
                font-size: 28px;
                
            }
            @media only screen and (max-width: 1024px) {
                font-size: 26px;
                
            }
            @media only screen and (max-width: 920px) {
                font-size: 20px;
                font-weight: 400;
            }
        }
        .scaled {
            position: relative;
             .desktop-v {
            width: 100%;
            height: 800px;
            margin-top: 100px;
            position: relative;
            transform-origin: top left;
            
            .first {
                position: absolute;
                width: 323px;
                top: 0;
                .title {
                    font-family: PoppinsBold;
                    font-size: 24px;
                    color: var(--y-grey3);
                    text-transform: uppercase;
                    position: absolute;
                    top: 33px;
                    left: 118px;
                    margin: 0;
                    text-align: left;
                    line-height: 24px;
                }
                .desc {
                    font-family: PoppinsRegular;
                    font-size: 17px;
                    color: #9E9E9E;
                    position: absolute;
                    top: 123px;
                    text-align: left;
                   
                }
                img {
                    width: 102px;
                }

                @media only screen and (max-width:1200px) {
                    left: 0;
                }
            }
            .second {
                position: absolute;
                width: 323px;
                right: 29px;
                .title {
                    font-family: PoppinsBold;
                    font-size: 24px;
                    color: var(--y-grey3);
                    text-transform: uppercase;
                    position: absolute;
                    top: 33px;
                    left: 118px;
                    margin: 0;
                    text-align: left;
                    line-height: 24px;
                }
                .desc {
                    font-family: PoppinsRegular;
                    font-size: 17px;
                    color: #9E9E9E;
                    position: absolute;
                    top: 123px;
                    text-align: left;
                   
                }

                img {
                    width: 102px;
                }
                @media only screen and (max-width:1200px) {
                    left: 0;
                    top: 230px;
                    
                }
            }
            .third {
                position: absolute;
                width: 323px;
                left: 0;
                bottom: 103px;
                .title {
                    font-family: PoppinsBold;
                    font-size: 24px;
                    color: var(--y-grey3);
                    text-transform: uppercase;
                    position: absolute;
                    top: 33px;
                    left: 118px;
                    margin: 0;
                    text-align: left;
                    line-height: 24px;
                }
                .desc {
                    font-family: PoppinsRegular;
                    font-size: 17px;
                    color: #9E9E9E;
                    position: absolute;
                    top: 123px;
                    text-align: left;
                   
                }

                img {
                    width: 102px;
                }

                @media only screen and (max-width:1200px) {
                    left: 0;
                    bottom: 230px;
                }
            }
            .fourth {
                position: absolute;
                position: absolute;
                width: 323px;
                right: 0;
                bottom: 103px;
                .title {
                    font-family: PoppinsBold;
                    font-size: 24px;
                    color: var(--y-grey3);
                    text-transform: uppercase;
                    position: absolute;
                    top: 33px;
                    left: 118px;
                    margin: 0;
                    text-align: left;
                    line-height: 24px;
                }
                .desc {
                    font-family: PoppinsRegular;
                    font-size: 17px;
                    color: #9E9E9E;
                    position: absolute;
                    top: 123px;
                    text-align: left;
                   
                }

                img {
                    width: 102px;
                }
                @media only screen and (max-width:1200px) {
                    left: 0;
                    bottom: -6px;
                }
            }

            .matrix-b{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                img {
                    max-width: 546px;
                }
                @media only screen and (max-width:1200px) {
                    left: 67%;
                }
                @media only screen and (max-width:1024px) {
                    left: 72%;
                    
                }
                @media only screen and (max-width:930px) {
                    display: none;
                    
                }
            }

        }

            @media only screen and (max-width:930px) {
                display: none;
                
            }

        }

        .grid-section {

            display: none;
            flex-wrap: wrap;
            margin-top: 60px;
            justify-content: space-between;
            .item {

                width: 45%;
                padding: 10px;
                margin-bottom: 30px;
                .title {
                    font-family: PoppinsBold;
                    font-size: 24px;
                    color: var(--y-grey3);
                    text-transform: uppercase;

                    text-align: center;
                    line-height: 24px;
                    margin-bottom: 30px;
                }
                .description {
                    font-family: PoppinsRegular;
                    font-size: 17px;
                    color: #9E9E9E;
                    text-align: center;
                }
                img {
                    width: 102px;
                    margin: 0 auto 30px auto;
                    display: block;
                }

                @media only screen and (max-width:620px) {
                    width: 100%;
                    
                }
            }

            @media only screen and (max-width:930px) {
                display: flex;
                
            }

        }
        
    }

    
    &.content-six { 

        color:var(--y-grey3);
        .title {
            font-family: PoppinsRegular;
            text-align: center;
            font-size: 30px;
            font-weight: 600;
            margin: 0;
            @media only screen and (max-width: 1280px) {
                font-size: 28px;
                
            }
            @media only screen and (max-width: 1024px) {
                font-size: 26px;
                
            }
            @media only screen and (max-width: 920px) {
                font-size: 20px;
                font-weight: 400;
            }
        }
        .description {
            
            font-family: BebasNeueBold;
            font-size: 72px;
            text-align: center;

            @media only screen and (max-width: 1280px) {
                font-size: 65px;
                
            }
            @media only screen and (max-width: 920px) {
                font-size: 55px;
                
            }
            @media only screen and (max-width: 820px) {
                font-size: 46px;
                
            }
            @media only screen and (max-width: 660px) {
                font-size: 36px;
                
            }
        }

        .grid-section {
            display: flex;
            justify-content: space-between;
            .item {
                padding: 20px;
                width: 30%;
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    max-width: 200px;
                }
                .title {
                    color: var(--y-grey3);
                    text-transform: uppercase;
                    font-size: 24px;
                    font-family: BebasNeueRegular;
                }
                .description {
                    font-family: PoppinsRegular;
                    font-size: 16px;
                    color: var(--y-grey6);
                    line-height: 27px;
                    padding-top: 20px;
                    @media only screen and (max-width: 820px) {
                        max-width: 100%;
                        
                    }
                }
                @media only screen and (max-width: 820px) {
                    width: 100%;
                    
                }
            }
            @media only screen and (max-width: 820px) {
                flex-direction: column;
                
            }
        }

    }
    &.content-seven {
        max-width: 100%;
        background-color: var(--y-grey18);
        .inner-wrap {
            max-width: 1440px;
            margin: 0 auto;
            text-align: center;
            .bio-header {
                color: var(--y-grey3);
                display: inline-block;
                position: relative;
                z-index: 1;
                
                padding: 0 20px;
                .title {
                    font-family: PoppinsRegular;
                    text-align: center;
                    font-size: 30px;
                    font-weight: 600;
                    margin: 0;
                    @media only screen and (max-width: 1280px) {
                        font-size: 28px;
                        
                    }
                    @media only screen and (max-width: 1024px) {
                        font-size: 26px;
                        
                    }
                    @media only screen and (max-width: 920px) {
                        font-size: 20px;
                        font-weight: 400;
                    }
                }
                .description {
                    
                    font-family: BebasNeueBold;
                    font-size: 72px;
                    text-align: center;
                    background-color: var(--y-grey18);
                    padding: 0 20px;
                    @media only screen and (max-width: 1280px) {
                        font-size: 65px;
                        
                    }
                    @media only screen and (max-width: 920px) {
                        font-size: 55px;
                        
                    }
                    @media only screen and (max-width: 820px) {
                        font-size: 46px;
                        
                    }
                    @media only screen and (max-width: 660px) {
                        font-size: 36px;
                        line-height: 36px;
                        margin-top: 20px;
                        
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    width: 100vw;
                    height: 5px;
                    background: var(--y-grey3);
                    top: 59%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    z-index: -1;
                    @media only screen and (max-width: 660px) {
                        top: 75%;
                        
                    }
                    @media only screen and (max-width: 520px) {
                        display: none;
                        
                    }
                }
            }
            .grid-section {
                display: flex;
                justify-content: space-between;
                margin-top: 80px;
                .item {

                    padding: 20px;
                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .author-block {
                        margin-top: 40px;
                        text-transform: uppercase;
                        .author-name {
                            font-size: 43px;
                            line-height: 39px;
                            font-family: BebasNeueBold;
                            color: var(--y-grey3);
                        }
                        .author-subtitle {
                            font-family: BebasNeueBold;
                            font-size: 30px;
                            line-height: 39px;
                            color: #838E9C;
                        }
                    }
                    .body-grid {
                        margin-top: 20px;
                        .description {
                            font-size: 20px;
                            line-height: 27px;
                            font-family: PoppinsRegular;
                            text-align: center;
                            color: #9E9E9E;
                            @media only screen and (max-width: 1024px) {
                                font-size: 18px;
                                
                            }
                        }
                    }

                    @media only screen and (max-width: 960px) {
                        width: 100%;
                        
                    }
                    
                }
                @media only screen and (max-width: 960px) {
                    flex-direction: column;
                    margin-top: 40px;
                    
                }
            }
        }
        
    }
    &.content-eight {

        max-width: 100%;
        background-image: linear-gradient(357.87deg, rgba(49,66,90, 1) 8.45%, rgba(49,66,90, 0.7) 95.85%), url('../../../assets/img/challenges/r3_large.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        .inner-wrap {
            max-width: 1440px;
            margin: 0 auto;
            .title {
                color: var(--y-green1);
                font-family: PoppinsRegular;
                font-size: 30px;
                margin: 0;
                text-transform: uppercase;
                
            }
            .subtitle {
                color:white;
                font-family: BebasNeueBold;
                font-size: 68px;
                text-transform: uppercase;
                margin: 0;
                @media only screen and (max-width: 1280px) {
                    font-size: 65px;
                    
                }
                @media only screen and (max-width: 920px) {
                    font-size: 55px;
                    
                }
                @media only screen and (max-width: 820px) {
                    font-size: 46px;
                    
                }
                @media only screen and (max-width: 660px) {
                    font-size: 36px;
                    
                }
            }

            .grid-section {
                display: flex;
                justify-content: space-between;
                margin-top: 40px;
                .item {
                    width: 30%;
                    background-color: white;
                    opacity: 0.7;
                    padding: 25px;
                    border-radius: 24px;
                    
                    .text {
                        text-align: left;
                        color: #9E9E9E;
                        font-family: PoppinsRegular;
                        font-size: 20px;
                    }
                    .grid-footer {
                        .title {
                            color: var(--y-grey3);
                            font-size: 24px;
                            font-family: BebasNeueBold;

                        }
                        .subtitle{
                            font-size: 14px;
                            color: #838E9C;
                            font-family: PoppinsRegular;
                        }
                    }
                    @media only screen and (max-width: 820px) {
                        width: 100%;
                        margin-bottom: 30px;
                    }
                }
                @media only screen and (max-width: 820px) {
                    flex-direction: column;
                    
                }
            }
        
        }

    }

    @media only screen and (max-width: 920px) {
        padding: 30px 20px;
        
    }
}   

.landing-separator {
    color: white;
    text-transform: uppercase;
    background: url('../../../assets/img/degradado.png') no-repeat;
    background-position:right;
    background-color:var(--y-grey3);
    padding: 20px;
    background-size: contain;
    .title {
        font-family: BebasNeueBold;
        text-align: center;
        font-size: 72px;
        line-height: 108%;
        margin: 0;
        @media only screen and (max-width: 1280px) {
            font-size: 65px;
            
        }
        @media only screen and (max-width: 920px) {
            font-size: 55px;
            
        }
        @media only screen and (max-width: 820px) {
            font-size: 46px;
            
        }
        @media only screen and (max-width: 660px) {
            font-size: 36px;
            
        }
    }
    .description {
        font-family: PoppinsSemiBold;
        text-align: center;
        font-size: 30px;
        @media only screen and (max-width: 1280px) {
            font-size: 26px;
            
        }
        @media only screen and (max-width: 920px) {
            font-size: 24px;
            
        }
        @media only screen and (max-width: 820px) {
            font-size: 22px;
            
        }
        @media only screen and (max-width: 660px) {
            font-size: 20px;
            
        }
    }
    @media only screen and (max-width: 660px) {
        background-size: cover;
        
    }
}

.count-wrapper {

    background-color: #E8EDEC;
    .title-header {
        float: right;
        width: 100%;
        margin-top: 23px;
        text-align: center;
        p {
            color: var(--y-grey3);
            font-family: PoppinsRegular;
            font-size: 48px;
            @media only screen and (max-width: 620px) {
                font-size: 32px;
                
            }
        }
        
        
    }
    .inner-wrap {
        margin: 0 auto;
        max-width: 1440px;
        padding: 70px 40px;
        @media only screen and (max-width: 620px) {
            padding: 30px 20px;
            
        }
    }
}


.box-btn {
    max-width: 761px;
    margin: 30px auto;
    padding: 0 20px;
    &.landing {
    .box-desktop {
        &.override-main {
            input {
               min-height: 55px;
               border: 2px solid var(--y-green1)!important;
               &::placeholder{
                color: var(--y-grey9) !important;
                font-family: PoppinsRegular;
                
                }
                
            }
            .ui {
                &.action {
                    input {
                        @media only screen and (max-width: 950px) {
                            border-top-right-radius: 0.28571rem !important;
                            border-bottom-right-radius: 0.28571rem !important;
                            border-right-color: var(--y-green1)!important;
                        }
                    }
                }
            }
            button {
                min-height: 55px;
                box-shadow: none!important;
                font-size: 20px!important;
                @media only screen and (max-width: 950px) {
                    display: none!important;

                }
            }
        }    
        
    }

    @media only screen and (max-width: 820px) {
        min-width: 100%;
        
      }
}

.f-center {
    button {
        font-size: 20px!important;
    }
}

}
