
.button__bar {
    display: inline-block;
    vertical-align: middle;
    margin: auto;
    padding: 0;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 40px;
    width: 70px;
    text-align: center;
    margin-top: 20px;
  }
  
  .button__bar li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 3px;
    padding: 0;
  }
  
  .button__bar li button {
    border: none;
    background-color: var(--y-grey6);
    color: transparent;
    cursor: pointer;
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 7.5px;
  }
  
  .button__bar li.slick-active button {
    background-color: var(--y-green1);
  }
  
  .button__bar li button:hover,
  .button__bar li button:focus {
    background: var(--y-green1);
    outline: 0;
  }
  