.std-options {
    margin-top: 40px;
    margin-bottom: 40px;
    .std-options-header{
        p {
            font-family: PoppinsMedium;
            font-size: 14px;
            color: var(--y-grey3);
        }
    }
    .std-options-body {
        .std-options-item {
            border-bottom: solid 1px #ececec;
            padding: 20px 20px 5px 20px;
            font-family: PoppinsRegular;
            .std-options-title {
                font-size: 12px;
                color: rgba(50, 72, 95, 0.4);
                
            }
            .std-options-description {
                font-size: 14px;
                color: var(--y-grey3);
            }
            p {
                margin-bottom: 5px;
            }
            &:last-child{
                border: none;
            }
        }
    }
    @media only screen and (max-width: 720px) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}