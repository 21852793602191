.practice-preview-wrapper {
    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    position: relative;
    .inner {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        min-height: 100%;
        flex-direction: column;

        .pr-nav {
            display: flex;
            border-bottom: 1px solid lightgray;
            padding: 0 20px;
            justify-content: space-between;
            font-family: PoppinsSemiBold;
            font-size: 14px;
            align-items: center;
            height: 50px;
            .pr-item {
                flex-basis: 0%;
                flex-shrink: 1;
                flex-grow: 0.48;
                color: var(--y-grey3);
                font-size: 1em;
                display: flex;
                justify-content: center;
                text-align: center;
                height: 100%;
                align-items: center;
                position: relative;
                cursor: pointer;
                &.active {
                    &:before {
                        content: "";
                        bottom: -3px;
                        width: 100%;
                        height: 4px;
                        background-color: var(--y-grey3);
                        position: absolute;
                    }
                }
            }

            @media only screen and (min-width: 921px) {
               display: none;
            }
        }

        .pr-flex {
            flex: 1;//parent with min-height no inherit height to child: fix here: https://stackoverflow.com/questions/8468066/child-inside-parent-with-min-height-100-not-inheriting-height/8468131
            display: flex;
            padding: 5vw 30px;
            align-items: stretch;

            .pr-aside {
                flex-grow: 0;
                flex-shrink: 0;
                width: 215px;
                padding: 10px;
                @media only screen and (max-width: 920px) {
                    display: none;
            
                }
            }
            .pr-content {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: auto;
                padding-left: 3vw;
            }

            @media only screen and (max-width:520px) {
                padding: 5vw 20px;
            }
  
        }
        .btn-practice-wrapper {
            padding: 32px 0;
            @media only screen and (max-width: 420px) {
                text-align: center;
                padding: 0 0 48px 0;
             }
        }
        .practice-preview-header {
           
            .practice-preview-header-title {
                font-size: 22px;
                text-align: left;
                text-transform: uppercase;
                font-family: PoppinsSemiBold;
                color: var(--y-grey3);
                
                 @media only screen and (max-width: 420px) {
                    font-size: 18px;
                 }
            }
            .practice-preview-header-subtitle {
                font-family: PoppinsRegular;
                font-size: 14px;
                text-align: left;
                padding-bottom: 60px;
                color: var(--y-grey3);
               
                @media only screen and (max-width: 420px) {
                    font-size: 14px;
                 }
                span {
                    color: var(--y-green1);
                }

                @media only screen and (max-width: 420px) {
                    padding-bottom: 30px;
                 }
            }

            @media only screen and (max-width: 918px) {
                padding-top: 0;
             }
        }
        .preview-grid {
            display: flex;
            flex-wrap: wrap;
            .item {
                width: calc(100%/var(--item-per-row-preview) - 20px);
                margin-left: 10px;
                margin-right: 10px;
                background: #fff;
                box-shadow: 0 0 10px rgba(0,0,0,.25);
                border-radius: 12px;
                overflow: hidden;
                margin-bottom: 48px;
                display: flex;
                flex-direction: column;
                cursor: pointer;
                position: relative;
                .item-cover {
                    width: 100%;
                    padding-top: calc(100% * (2/3));
                    position: relative;
                    overflow: hidden;
                    .item-expanded {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        z-index: 2;
                        background-color: #fff;
                        padding-bottom: 10px;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
                .item-footer {
                    background: var(--y-grey3);
                    padding: 10px 15px;
                    margin-top: auto;
                    min-height: 58.4px;
                    .title {
                        font-family: BebasNeueBold;
                        font-size: 18px;
                        color: var(--y-green1);
                    }
                }

                .item-counter {
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding-left: 8px;
                    padding-right: 8px;
                    margin-left: 8px;
                    margin-right: 8px;
                    margin-top: 8px;
                    color: var(--y-green1);
                    font-family: PoppinsSemiBold;
                    font-size: 14px;
                    background-color: rgba(49,67,91,.75);
                    text-transform: uppercase;
                    border-radius: 3px;
                    z-index: 3;
                    
                }

                .item-time {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding-left: 8px;
                    padding-right: 8px;
                    margin-left: 8px;
                    margin-right: 8px;
                    margin-top: 8px;
                    color: var(--y-green1);
                    font-family: PoppinsSemiBold;
                    font-size: 14px;
                    background-color: rgba(49,67,91,.75);
                    text-transform: uppercase;
                    border-radius: 3px;
                    z-index: 3;
                    i {
                        &::before {
                            
                            color: var(--y-green2) !important;
                            text-shadow: 0 0 15px var(--y-green2);

                        }
                    }

                }
            }
        }
      
    }

    @media only screen and (max-width: 1230px) {
        --item-per-row-preview: 3;
     }

     @media only screen and (max-width: 620px) {
        --item-per-row-preview: 2;
     }

     @media only screen and (max-width: 420px) {
        --item-per-row-preview: 1;
     }
}

