.ui.modal {
    i.close.icon {
        top: 1.0535rem;
        right: 1rem;
        color: rgba(0, 0, 0, .87);
    }

    .scrolling.content {

        .proImage {
            position: relative;
            margin-bottom: 20px;

            img.ui.image.avatar {
                object-fit: cover;
                z-index: 2;
                left: calc(50% - 75px);

                &.broken {
                    z-index: 0;
                }
            }

            span.initials {
                display: inline-block;
                position: absolute;
                text-align: center;
                color: black;
                background-color: white;
                height: 150px;
                width: 150px;
                z-index: 1;
                border-radius: 50%;
                border: 1px solid #F4F4F4;
                font-size: 5em;
                padding: 0.3em;
                line-height: 1.5em;
                left: calc(50% - 75px);

                &.visible {
                    z-index: 2;
                }
            }
        }

        .title {
            display: block;
            text-align: center;
            font-size: 1.25em;
            line-height: 1.5em;
            font-weight: 700;
            margin-bottom: 10px;
        }

        div.subtitle {
            text-align: center;
            font-size: 1.2em;
            line-height: 2em;

            i {
                margin-right: 5px;
            }

            &.language {
                margin-bottom: 20px;
            }
        }

        .text {
            display: block;
            font-size: 1em;
            line-height: 1.25em;
            margin-bottom: 20px;
        }

        video {
            width: 100%;
            height: auto;
            display: block;
            margin-right: 0;
            margin-bottom: 20px;
        }

        .actions {
            display: flex;
            justify-content: space-around;

            .ui.button {
                width: 150px;
                @media (max-width: 720px) {
                    width: 52px;

                    .buttonLabel {
                        display: none;
                    }
                }
            }
        }
    }
}