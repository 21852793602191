.diagnosis-grid {
    display: flex;
    flex-wrap: wrap;
    // max-width: 1070px;
    margin: 50px auto;
    .item {
        width:calc( (100% / var(--item-per-row) ) - 48px);
        margin-left: 24px;
        margin-right: 24px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 48px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        position: relative;

        .icon-practice-type {
            position: absolute;
            top: 0;
            left: 0;
            padding: 8px 2px;
            background: white;
            z-index: 5;
            font-size: 27px;
            border-bottom-right-radius: 6px;
        }

        .disabled-layout{
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.45);
            z-index: 11;
            
            .centered-layout {
                width: 100%;
                padding-top: 75%;
                position: relative;
                overflow: hidden;

                div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    i {  
                        animation: animationLocks 1s forwards 0s ease-in;
                        font-size: 120px;
                        &:before{
                            color: var(--y-green1);
                            text-shadow: 0 0 15px var(--y-green1);
                        }
                    }
                }
            }
            

        }
        .item-label {
            background: white;
            padding: 5px 10px;
            top: 0;
            left: 0;
            position: absolute;
            display: inline-block;
            z-index: 10;
            font-family: BebasNeueBold;
            font-size: 16px;
        }
        .item-band {
            height: 38px;
            background-color: var(--y-green1);
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 5px;
            align-items: center;
            justify-items: center;
            .item-icon {
                text-align: center;
                border-right: solid 1px white;
                width: 100%;
                i{
                    &:before{
                        color: var(--y-grey3);
                        font-size: 25px;
                    }
                }
                span {
                    display: inline-block;
                    font-family: BebasNeueBold;
                    color: var(--y-grey3);
                    font-size: 13px;
                    line-height: 13px;
                }

                &:last-child{
                    border-right: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

        }

        .item-footer {
            min-height: 104px;
            background: white;
            padding: 10px 15px;

            div {
                margin-bottom: 5px;
            }
            .title{
                font-family: BebasNeueBold;
                font-size: 17px;
                color: var(--y-grey3);
               
            }
            .subtitle{
                color: var(--y-grey6);
                font-size: 15px;
                font-family: PoppinsRegular;
            }
            .stats {
                color: var(--y-grey3);
                font-size: 13px;
                span{
                    font-family: PoppinsRegular;
                    &:first-child{
                        font-family: PoppinsMedium;
                    }
                    
                }
            }
        }

        .item-header{
            min-height: 104px;
            padding: 20px 20px 20px 40px;
            position: relative;
            .subtitle {
                color: var(--y-grey6);
                font-family: PoppinsMedium;
                font-size: 14px;
                margin-top: 5px;
                text-align: center;
            }
            .description {
                
                h1 {
                    color: var(--y-grey3);
                    font-size: 18px;
                    font-family: PoppinsMedium;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                    -webkit-line-clamp: 2;
                    line-height: 22px;
                    max-height: 50px;
                    text-align: center;
                }
                
            }

            &:after {
                content: attr(data-index);
                position: absolute;
                padding: 8px 15px;
                color: white;
                font-size: 27px;
                background-color: var(--y-grey9);
                top: 0;
                font-family: BebasNeueBold;
                border-bottom-right-radius: 6px;
                z-index: 6;
                left: 0;
            }

        }
        .item-cover{
            position: relative;
            width: 100%;
            padding-top: 75%; //4:3
            position: relative;
            overflow: hidden;
            .wrapper-image{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 2;
                &.asana {
                    background-color: var(--y-grey9);
                    img {
                        padding: 15px;
                    }
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    &.challenge {
                        object-fit: contain;
                    }
                }
                .a-overlay{
                    position: absolute;
                    z-index: 5;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .play-image{
                        width: 150px;
                        height: 150px;
                    }
                }


            }
        }

        @media only screen and (min-width: 1025px) {
            --item-per-row: 4;
        }
        @media only screen and (max-width: 1024px) {
            --item-per-row: 3;
        }
        @media only screen and (max-width: 820px) {
            --item-per-row: 2;
        }
        @media only screen and (max-width: 520px) {
            --item-per-row: 1;
            margin-bottom: 20px;
        }

        @media only screen and (max-width: 420px) {
            width:calc( (100% / var(--item-per-row) ) - 24px);
            margin-left: 12px;
            margin-right: 12px;
           
        }

    }

    @media only screen and (max-width: 620px) {
        margin: 20px auto;
    }

}


@keyframes animationLocks {
    0%{
        transform: scale(1.5) translateY(-150%) skewX(0deg) skewY(0deg);
        }
}
