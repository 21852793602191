.brand-primary{
    color:var(--brand-primary, #30425a)!important;
} 
.brand-primary-light{
    color:var(--brand-primary-light, #98a3af)!important;
}
.brand-primary-extra-light{
    color:var(--brand-primary-extra-light, #ccd1d7)!important;
}
.brand-secondary{
    color:var(--brand-secondary, #1fd5b9)!important;
}
.brand-secondary-dark{
    color:var(--brand-secondary-dark, #1bb89f)!important;
}
.brand-black {
    color:var(--brand-black, #000000)!important;
}
.brand-white {
    color:var(--brand-white, #ffffff)!important;
}
.dark-grey {
    color:var(--dark-grey, #3d3d3d)!important;
}
.dark-grey-2 {
    color:var(--dark-grey-2,#686c71)!important;
}
.dark-grey-3 {
    color:var(--dark-grey-3, #84898f)!important;
}
.dark-grey-4 {
    color:var(--dark-grey-4, #eaecef)!important;
}
.dark-grey-14 {
    color:var(--y-grey14, #C4C4C4)!important;
}
.light-grey-1 {
    color:var(--light-grey-1, #b2bac4)!important;
}
.dark-grey-2 {
    color:var(--dark-grey-2, #f8f8f8)!important;
}
.brand-error {
    color:var(--brand-error, #e20d0d)!important;
}
.red-color-secondary {
    color:var(--red-color-secondary, #EB5757)!important;
}
.brand-positive {
    color:var(--brand-positive, #2dcc70)!important;
}
.brand-alarm {
    color:var(--brand-alarm, #ffcf00)!important;
}
.blue-link {
    color:var(--blue-link,#3598d4)!important;
}