.st-wrapper {
    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    position: relative;
    .inner {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        .st-flex {
            display: flex;
            width: 100%;
            padding: 40px 20px;
            flex: 1;
            .st-aside {
                flex-grow: 0;
                flex-shrink: 0;
                width: 215px;
                padding: 0 10px;
                .st-aside-menu {
                    font-size: 14px;
                    position: sticky;
                    top: 20px;
                    .item {
                        padding: 5px 10px;
                        font-size: 1em;
                        color: var(--y-grey3);
                        font-family: PoppinsSemiBold;
                        position: relative;
                        margin-bottom: 10px;
                        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
                        cursor: pointer;
                        &:before{
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 4px;
                            background-color: var(--y-grey3);
                            bottom: 0;
                            opacity:0;
                            transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
                        }
                        &.active {
                            &::before{
                                opacity: 1;
                            }
                        }
                        &:hover {
                            color: var(--y-green1);
                            &::before{
                                background-color: var(--y-green1);
                            }
                        }
                        span {
                            margin-left: 10px;
                            display: inline-block;
                        }
                    }
                }
                @media only screen and (max-width: 920px) {
                    display: none;
                 }
            }
            .st-content {
                flex-grow: 1;
                flex-basis: auto;
                flex-shrink: 1;
                padding-left: 3vw;
                min-width: 0;//fix bug flexchilds with whitspace-nowrap
                .st-grid-wrapper {
                    margin-top: 40px;
                    margin-bottom: 40px;
                }
                @media only screen and (max-width: 920px) {
                    padding-left:0;
                 }
            }

            @media only screen and (max-width: 420px) {
                padding: 5vw 10px 0px 10px;
             }
        }
        
        .st-nav {
            display: flex;
            border-bottom: 1px solid lightgray;
            padding: 0 20px;
            justify-content: space-between;
            font-family: PoppinsSemiBold;
            font-size: 14px;
            align-items: center;
            height: 50px;
            .st-item {
                flex-basis: 0%;
                flex-shrink: 1;
                flex-grow: 0.48;
                color: var(--y-grey3);
                font-size: 1em;
                display: flex;
                justify-content: center;
                text-align: center;
                height: 100%;
                align-items: center;
                position: relative;
                cursor: pointer;
                &.active {
                    &:before {
                        content: "";
                        bottom: -3px;
                        width: 100%;
                        height: 4px;
                        background-color: var(--y-grey3);
                        position: absolute;
                    }
                }
            }

            @media only screen and (min-width: 920px) {
               display: none;
            }
        }

        .st-view{
            display: none;
            &.active {
                display: block;
            }
        }
        .st-header {
            font-size: 12px;
            color: var(--y-grey3);
            padding: 0 10px;
            
            .st-title {
                font-size: 1.5em;
                font-family: PoppinsSemiBold;
                @media only screen and (min-width: 920px) {
                    font-size: 1.9em;
                 }
            }
            .st-description {
                font-size: 1.2em;
                font-family: PoppinsRegular;
            }

        }

        .st-empty {
            padding: 20px;
            background-color: #f2f2f2;
            color: var(--y-grey3);
            font-size: 14px;
            font-family: PoppinsRegular;
            .st-empty-message {
                font-size: 1em;
            }
            .st-empty-btn {
               
                @media only screen and (max-width: 720px) {
                    font-size: 12px;
                 }
                 @media only screen and (min-width: 721px) {
                    font-size: 14px;
                 }
            }
    
        }
        
    }
}