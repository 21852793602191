.onboard-layer {
	width: 100vw;
	height: 100%;
	.onboard-wrapper {
		height: 100%;
		position: relative;
		padding: 1.5rem 1rem;
		padding-bottom: 3.5rem;
		display: flex;
        display: -webkit-flex;
		flex-direction: column;
		justify-content: flex-end;
		.onboard-header {
			display: flex;
            display: -webkit-flex;
			justify-content: center;
			align-items: center;
			min-height: 0;
			position: relative;
            height: 100%;
			img {
				width: 18.2em;
				height: 100%;
				object-fit: contain;
				@media only screen and (min-width: 1024px) {
					margin-right: auto;
					width: 23.2em;
				}
			}
			&.long {
				margin-bottom: 20px;
				img {
					width: 18.5em;
				}
				@media only screen and (min-width: 321px) {
					img {
						width: 21.5em;
					}
				}
				@media only screen and (min-width: 1024px) {
					margin-bottom: 0;

					img {
						&.slide-tablet {
							@media only screen and (min-width: 1024px) {
								position: absolute;
								bottom: 0;
								height: auto;
								left: -16px;
								width: 60vw !important;
								transform: scale(1.2);
							}
						}
					}
				}

				@media only screen and (max-height: 650px) {
					margin-bottom: 0px;
				}
			}

			@media only screen and (min-width: 1024px) {
				flex-basis: 60%;
				flex-shrink: 1;
				flex-grow: 0;
				height: 100%;
			}
		}

		.onboard-body {
			display: flex;
            display: -webkit-flex;
			align-items: center;
			flex-direction: column;
            padding: 0 15px;

			.onboard-title {
				text-align: center;
				margin-bottom: 40px;
				color: var(--brand-primary);
				p {
					font-family: PoppinsRegular;
					font-size: 2.4em;
					text-transform: uppercase;
				}

				span {
					font-family: PoppinsSemiBold;
				}
				&.short {
					margin-bottom: 20px;
					@media only screen and (max-height: 650px) {
						margin-bottom: 15px;
					}
				}

				@media only screen and (max-height: 650px) {
					margin-bottom: 15px;
				}
			}

			.onboard-subtitle {
				text-align: center;
				margin-bottom: 20px;
				color: var(--brand-primary);
				p {
					font-family: PoppinsRegular;
					font-size: 1.7em;
					text-transform: uppercase;
				}

				span {
					font-family: PoppinsSemiBold;
				}

				@media only screen and (max-height: 650px) {
					margin-bottom: 20px;
				}
			}
			.onboard-description {
				text-align: center;
				margin-bottom: 20px;
				color: var(--brand-primary);
				p {
					font-family: PoppinsRegular;
					font-size: 1.3em;
				}
				span {
					font-family: PoppinsSemiBold;
				}
			}

			.onboard-login {
				font-size: 1.2em;
				font-family: PoppinsRegular;
				color: var(--brand-primary);
				span {
					font-family: PoppinsSemiBold;
				}
			}

			@media only screen and (min-width: 1024px) {
				flex-basis: 40%;
				flex-shrink: 1;
				flex-grow: 0;
				padding: 20px;
				height: auto;
			}
		}

		.normal-btn {
			color: white;
			font-family: PoppinsRegular;
			padding: 0.75em 1.2em;
			text-transform: uppercase;
			background-color: var(--y-green1);
			font-size: 1.8em;
			box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
			border-radius: 3px;
			margin-bottom: 20px;
			display: inline-block;
			margin-top: auto;
		}

		@media only screen and (min-width: 1024px) {
			flex-direction: row;
			justify-content: center;
			align-items: center;
			display: flex;
            display: -webkit-flex;
			padding-left: 0;
		}
	}
}
.body-nxt-btn {
	background-color: var(--y-green1);
	color: white;
	font-family: PoppinsRegular;
	font-size: 1.2em;
	padding: 8px 16px;
	text-transform: uppercase;
	border-radius: 11px;
}

.skip-btn {
	position: absolute;
	top: 30px;
	right: 0;
	color: white;
	font-family: PoppinsRegular;
	font-size: 1.2em;
	background-color: var(--y-green1);
	border-radius: 11px 0px 0px 11px;
	padding: 8px 16px;
	text-transform: uppercase;
	z-index: 10;
}

$slider1_bg_1: "../../../../assets/img/onboard/slide_1_bg_1.png";
$slider1_bg_2: "../../../../assets/img/onboard/slide_1_bg_2.png";
$slider1_bg_3: "../../../../assets/img/onboard/slide_1_bg_3.png";
$slider2_bg_1: "../../../../assets/img/onboard/slide_2_bg_1.png";
$slider2_bg_2: "../../../../assets/img/onboard/slide_2_bg_2.png";
$slider2_bg_3: "../../../../assets/img/onboard/slide_2_bg_3.png";
$slider3_bg_1: "../../../../assets/img/onboard/slide_3_bg_1.png";
$slider3_bg_2: "../../../../assets/img/onboard/slide_3_bg_2.png";
$slider3_bg_3: "../../../../assets/img/onboard/slide_3_bg_3.png";
$slider4_bg_1: "../../../../assets/img/onboard/slide_4_bg_1.png";
$slider4_bg_2: "../../../../assets/img/onboard/slide_4_bg_2.png";
$slider4_bg_3: "../../../../assets/img/onboard/slide_4_bg_3.png";
$slider5_bg_1: "../../../../assets/img/onboard/slide_5_bg_1.png";
$slider5_bg_2: "../../../../assets/img/onboard/slide_5_bg_2.png";
$slider5_bg_3: "../../../../assets/img/onboard/slide_5_bg_3.png";

.wrapper-slick-slider {
	position: relative;
	font-size: 10px;
	&.bg-slide-0 {
		background-image: url(#{$slider4_bg_2}), url(#{$slider1_bg_1});
		background-position: top center, top center;
		background-repeat: no-repeat, no-repeat;
		background-size: contain, contain;
		@media only screen and (min-width: 1024px) {
			background-size: contain, cover;
		}

		.onboard-layer {
			.onboard-wrapper {
				justify-content: center;
				.onboard-header {
					position: relative;
					img {
						width: 100% !important;
						&.slider-bg-secondary {
							position: absolute;
							max-width: 350px;
							width: 60% !important;
							bottom: 40px;
							right: -8px;
							height: auto;
						}
						&.slide-tablet {
							@media only screen and (min-width: 1024px) {
								position: absolute;
								bottom: 0;
								height: auto;
								left: -16px;
								width: 60vw !important;
								transform: scale(1.2);
							}
						}
					}
				}
			}
		}
	}
	&.bg-slide-1 {
		background-image: url(#{$slider4_bg_2}), url(#{$slider2_bg_2}), url(#{$slider2_bg_1});
		background-position: right top, center top;
		background-repeat: no-repeat, no-repeat;
		background-size: contain, contain, cover;
		@media only screen and (min-width: 1024px) {
			background-image: url(#{$slider4_bg_2}), url(#{$slider1_bg_1});
			background-position: top center, top center;
			background-repeat: top center, no-repeat;
			background-size: contain, contain;
		}
	}
	&.bg-slide-2 {
		background-image: url(#{$slider3_bg_2}), url(#{$slider3_bg_1});
		background-position: right top, center top;
		background-repeat: no-repeat, no-repeat, no-repeat;
		background-size: contain, contain;
	}
	&.bg-slide-3 {
		background-image: url(#{$slider4_bg_2}), url(#{$slider4_bg_1});
		background-position: right top, center top;
		background-repeat: no-repeat, no-repeat, no-repeat;
		background-size: contain, contain;
	}
	&.bg-slide-4 {
		background-image: url(#{$slider4_bg_2}), url(#{$slider5_bg_2}), url(#{$slider5_bg_1});
		background-position: right top, right top, center top;
		background-repeat: no-repeat, no-repeat, no-repeat;
		background-size: contain, contain, cover;
	}

	@media only screen and (min-width: 1024px) {
		background-image: url("../../../../assets/img/onboarding_background_desktop.png");
	}

	@media only screen and (min-width: 321px) {
		font-size: 11px;
	}

	@media only screen and (min-width: 720px) {
		font-size: 14px;
	}

	@media only screen and (min-width: 1024px) {
		font-size: 16px;
	}

	@media only screen and (min-width: 1366px) {
		font-size: 20px;
	}

	@media only screen and (min-width: 1600px) {
		font-size: 23px;
	}

	@media only screen and (min-width: 2440px) {
		font-size: 26px;
	}
}

.wrapper-slick-slider {
	height: 100%;
	.slick-slider {
		height: 100%;
		.slick-list {
			height: 100%;
			.slick-track {
				height: 100%;
				.slick-slide {
					height: 100%;
					& > div {
						height: 100%;
					}
				}
			}
		}
	}
	.dots-list {
		display: flex;
        display: -webkit-flex;
		justify-content: center;
		position: absolute;
		bottom: 8px;
		margin: 0;
		padding: 1rem 0;
		transform: translateX(-50%);
		left: 50%;
		list-style-type: none;

		li {
			margin: 0 0.25em;
			&:first-child {
				display: none;
			}

			div {
				display: block;
				width: 1em;
				height: 1em;
				padding: 0;
				border: none;
				border-radius: 100%;
				background-color: var(--y-grey3);
				text-indent: -9999px;
			}
		}

		li.slick-active div {
			background-color: var(--y-green1);
		}

		&.hide-dots {
			display: none;
		}
	}
}

.onboard-slick-custom-btn {
	cursor: pointer;
	display: flex;
    display: -webkit-flex;
	height: 100%;
	-webkit-box-pack: center;
	justify-content: center;
	opacity: 1;
	position: absolute;
	bottom: 0px;
	z-index: 1;
	background: transparent;
	border-width: initial;
	border-style: none;
	border-color: initial;
	border-image: initial;
	padding: 0px;
	transition: opacity 0.2s ease 0s;
	align-items: center;
	height: 40px;
	width: 40px;

	&.prev-a,
	&.next-a {
		top: 50%;
		transform: translateY(-50%);
	}

	&.prev-a {
		left: 0;
		padding-left: 5px;
		padding-bottom: 10px;
	}
	&.next-a {
		right: 0;
		padding-right: 5px;
		padding-bottom: 10px;
	}

	&.slick-disabled {
		display: none;
	}
	&.hide-arrow {
		display: none;
	}
}
.slider-bg-secondary {
	position: absolute;
	max-width: 350px !important;
	width: 60% !important;
	bottom: 40px;
	right: -8px;
	height: auto;
}
