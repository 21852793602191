.s-cart-wrapper {
    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    position: relative;
    .inner {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        .s-cart-flex {
            display: flex;
            width: 100%;
            padding: 40px 20px;
            
            justify-content: space-between;
            .s-cart-list-wrap{
                flex-grow: 0.6;
                flex-basis: 0%;
                flex-shrink: 1;
                .s-cart-header{
                    font-size: 12px;
                    color: var(--y-grey3);
                    padding: 0 10px;
                    font-family: PoppinsSemiBold;
                    .s-cart-header-title {
                        font-size: 1.5em;
                    }
                    .s-cart-title {
                        font-size: 1.3em;
                        margin-top: 40px;
                        border-bottom: solid 1px lightgray;
                        padding-bottom: 10px;
                    }
                }
                .s-cart-list{
                    display: block;
                    margin-top: 20px;
                    padding: 0 10px;
                    position: relative;
                    .item {
                        margin-bottom: 10px;
                        display: flex;
                        border-radius: 7px;
                        overflow: hidden;
                        border: solid 2px lightgray;
                        position: relative;
                        .item-image{
                            width: 125px;
                            flex-basis: 125px;
                            flex-shrink: 0;
                            flex-grow: 0;
                            background-color: white;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }

                        .item-content {

                            padding: 10px 20px;
                            
                            .item-title {
                                word-break: break-all;
                                margin: 5px 0;
                            }
                            .item-time {
                                color: var(--brand-primary-extra-light);
                                i {
                                    &:before {
                                        color: var(--brand-primary-extra-light);
                                    }
                                }
                                    
                            }
                            

                            .action-remove {
                                color: var(--y-green1);
                                cursor: pointer;
                                margin-top: 10px;
                                display: inline-block;
                            }

                        }
                    }
                }

                @media only screen and (max-width: 720px) {
                    flex-grow: 1;
                 }
            }

            .s-cart-detail-wrap {
                flex-grow: 0.3;
                flex-basis: 0%;
                flex-shrink: 1;
                .s-cart-detail {

                    padding: 40px 20px;
                    background-color: #f2f2f2;
                    font-size: 14px;
                    position: sticky;
                    top: 20px;
                    .s-cart-amount{
                        font-family: PoppinsSemiBold;
                        color: var(--y-grey3);
                        font-size: 0.95em;
                    }
                    .s-cart-btn{
                        
                        @media only screen and (max-width: 720px) {
                            font-size: 12px;
                         }
                         @media only screen and (min-width: 721px) {
                            font-size: 14px;
                         }
                    }
                }

                &.desktop {
                    @media only screen and (max-width: 719px) {
                        display: none;
                     }
                }

                &.mobile {
                    flex-grow: 1;
                    @media only screen and (min-width: 720px) {
                        display: none;
                     }
                }
                
            }

            @media only screen and (max-width: 420px) {
                padding: 40px 5px;
             }
        }
    }
}


.s-cart-empty {
    padding: 20px;
    background-color: #f2f2f2;
    color: var(--y-grey3);
    font-size: 14px;
    font-family: PoppinsRegular;
    .s-cart-empty-message {
        font-size: 1em;
    }
    .s-cart-empty-btn {
        
        @media only screen and (max-width: 720px) {
            font-size: 12px;
         }
         @media only screen and (min-width: 721px) {
            font-size: 14px;
         }
    }

}