.pop-section {
    position: fixed;
    bottom: 70px;
    left: 30px;
    width: 360px;
    padding: 20px;
    background-color: white;
    z-index: 100;
    box-shadow: 0px 5px 17px rgba(49, 66, 90, 0.25);
    // border-radius: 20px;
    &.no-pointer {
        pointer-events: none;
        cursor: none!important;
    }
    &__header {
        color: var(--y-grey3);
        font-family: PoppinsSemiBold;
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 40px;
    }

    &__title {
        color: var(--y-green1);
        text-transform: uppercase;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding-right: 10px;
        padding-top: 13px;
        i {
            font-size: 22px;
            &::before {
                color: var(--y-green1);
            }
        }
    }

    &__image {
        img {
            width: 30px;
        }
        margin-right: 10px;
    }
    &__description {
        color: var(--y-grey3);
        font-family: PoppinsRegular;
        font-size: 14px;
        &--grey{
            margin-top: 20px;
            color: var(--grey4);
        }
    }
    &__footer {
        margin-top: 20px;
        padding: 20px;
        display: flex;
        justify-content: flex-end;
        
        span {
            color: white;
            text-transform: uppercase;
            background-color: var(--y-green1);
            cursor: pointer;
            font-family: PoppinsSemiBold;;
            padding: 10px 20px;
            display: inline-block;
            width: 100%;
            text-align: center;
            border-radius: 5px;
        }
        
    }


    @media only screen and (max-width: 420px) {
       
        width: calc(100% - 30px);
        margin-left: 10px;
        margin-right: 10px;
        left: 0;
        
     }
}

.pop-section-promotion {
    position: fixed;
    bottom: 70px;
    left: 30px;
    width: 360px;
    background-color: white;
    z-index: 100;
    box-shadow: 0px 5px 17px rgba(49, 66, 90, 0.25);
    border-radius: 10px;
    &.no-pointer {
        pointer-events: none;
        cursor: none!important;
    }
    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding-right: 10px;
        padding-top: 13px;
        i {
            font-size: 22px;
            &::before {
                color: white;
            }
        }
    }
    &__header {
        background: rgb(49,67,91);
        background: -moz-linear-gradient(90deg, rgba(49,67,91,1) 0%, rgba(31,213,185,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(49,67,91,1) 0%, rgba(31,213,185,1) 100%);
        background: linear-gradient(90deg, rgba(49,67,91,1) 0%, rgba(31,213,185,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#31435b",endColorstr="#1fd5b9",GradientType=1);
        height: 50px;
        padding: 20px 40px 20px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position: relative;
    }
    &__title {
        color: #fff;
        font-family: PoppinsSemiBold;
        font-size: 18px;
        text-align: center;
        text-transform: uppercase;
    }

    &__body {
        padding: 20px;
        
    }

    &__description {
        color: var(--y-grey3);
        font-family: PoppinsRegular;
        text-align: left;
        font-size: 14px;
        margin-bottom: 10px;
        &--grey{
            color: var(--grey4);
            text-align: center;
        }
    }
    &__counter {
        text-align: center;
        letter-spacing: 1px;
    }   

    &__footer {
        margin-top: 20px;
        padding: 20px;
        display: flex;
        justify-content: flex-end;
        
        span {
            color: white;
            text-transform: uppercase;
            background-color: var(--y-green1);
            cursor: pointer;
            font-family: PoppinsSemiBold;;
            padding: 10px 20px;
            display: inline-block;
            width: 100%;
            text-align: center;
            border-radius: 5px;
        }
        
    }

    @media only screen and (max-width: 420px) {
       
        width: calc(100% - 30px);
        margin-left: 10px;
        margin-right: 10px;
        left: 0;
        
     }
}