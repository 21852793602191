.heading-1,.heading-2, .heading-3, .paragraph, .caption, .paragraph-to-caption, .labeling, .landing-heading-2, .prices-text, .landing-heading-1, .landing-heading-3{

    font-family: var(--font-primary);
    color: var(--brand-primary);
    &.regular {
        font-family: var(--font-secondary);
    }
    &.white {
        color:var(--brand-white);
    }
    &.text-link {
        color: var(--blue-link);
    }
}

.heading-1 {
    font-size: 2rem;
    @media only screen and (max-width:720px) {
        font-size: 1.92rem;
    }
    @media only screen and (max-width:420px) {
        font-size: 1.85rem;
    }
}

.heading-2 {
    font-size: 1.71rem;
    line-height: 1;
    @media only screen and (max-width:720px) {
        font-size: 1.57rem;
    }
    @media only screen and (max-width:420px) {
        font-size: 1.42rem;
    }
}

.heading-3 {
    font-size: 1.28rem;
    @media only screen and (max-width:720px) {
        font-size: 1.21rem;
    }
    @media only screen and (max-width:420px) {
        font-size: 1.14rem;
    }
}

.paragraph {
    font-size: 1.14rem;
    @media only screen and (max-width:720px) {
        font-size: 1.07rem;
    }
    @media only screen and (max-width:420px) {
        font-size: 1rem;
    }
}

.paragraph-to-caption {
    font-size: 1.14rem;
    @media only screen and (max-width:720px) {
        font-size: 1.07rem;
    }
    @media only screen and (max-width:420px) {
        font-size: 0.85rem;
    }
}

.caption {
    font-size: 0.85rem;
}

.labeling {
    font-size: 1rem;
    @media only screen and (max-width:420px) {
        font-size: 0.85rem;
    }
}

.landing-heading-1 {
    font-size: 3.5rem;
    line-height: 1.4;
    @media (max-width: 799px) {
        font-size: 2.5rem;
    }
    @media (max-width: 420px) {
        font-size: 1.7rem;
    }
}

.landing-heading-2 {
    font-size: 2.14rem;
    line-height: 1.4;
    @media (max-width: 799px) {
        font-size: 1.8rem;
    }
    @media (max-width: 420px) {
        font-size: 1.4rem;
    }
}

.landing-heading-3 {
    font-size: 1.9rem;
    line-height: 1.4;
    @media (max-width: 799px) {
        font-size: 1.6rem;
    }
    @media (max-width: 420px) {
        font-size: 1.3rem;
    }
}

.prices-text {
    font-size: 1.85rem;
    line-height: 1.4;
    @media (max-width: 799px) {
        font-size: 1.8rem;
    }
    @media (max-width: 420px) {
        font-size: 1.14rem;
    }
}

.chars-readable {
    max-width: 90ch;
}