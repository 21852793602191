.wrapper-practice{

    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    .footer-section {
        margin-top: auto;
    }
    .inner {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        padding: 0;

        .fullscreen-background {        
            background: white;
            z-index: 9998;
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        .header-message{
            background-color: var(--y-grey10);
            display: flex;
            position: relative;
            min-height: 120px;
            padding: 32px 20px;
            justify-content: center;
            &:before{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 5vw;
                height: 8px;
                border: solid 12px white;
                box-sizing: content-box;
                @media only screen and (max-width:1200px) {
                    display: none;
                }
            }
            
            .header-content {
                max-width: 1000px;
                flex: 1;
                display: flex;
                .left {
                    flex: 2;
                    font-family: PoppinsRegular;
                    color: var(--y-grey6);
                    font-size: 18px;
                    text-align: left;
                    @media only screen and (max-width:960px) {
                        font-size: 16px;
                    }
                }
                .right {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    img {
                        max-width: 50px;
                        margin-bottom: 10px;
                        cursor: pointer;
                    }
                    i {
                        height: 20px;
                        line-height: 28px;
                        &::before {
                            color:var(--y-grey3);
                        }
                    }
                    p{
                        margin: 0;
                        font-family: BebasNeueBold;
                        font-size: 18px;
                        color: var(--y-grey3);
                    }
                    @media only screen and (max-width:820px) {
                        justify-content: flex-start;
                        margin-top: 20px;
                    }
                }

                @media only screen and (max-width:820px) {
                    flex-direction: column;
                }
                
            }
        }

        .wrapper-slides {
            padding: 20px 0px;
            margin-top: 10px;
            &.fullscreen-enabled {
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0!important;
                z-index: 9999;
                object-fit: contain;
                position: fixed !important;
                top: 0px !important;
                right: 0px !important;
                bottom: 0px !important;
                left: 0px !important;
                box-sizing: border-box !important;
                min-width: 0px !important;
                min-height: 0px !important;
                max-height: none !important;
                width: 100% !important;
                height: 100% !important;
                transform: none !important;
                margin: 0px auto !important;
            }
        
            .wrapper-slide{
                width: 100%;
                padding-top: 56.25%;
                overflow: hidden;
                position: relative;
                
                .preambule-layout {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 10;
                    pointer-events: none;
                    
                    div {
                        display: inline-flex;
                        padding: 10px 20px;
                        background-color: var(--y-green1);
                        border-radius: 6px;
                        justify-content: center;
                        align-items: center;

                        p{
                            font-size: 36px;
                            color: white;
                            font-family: BebasNeueBold;
                            
                            @media only screen and (max-width:620px) {
                                font-size: 32px;
                            }
                            @media only screen and (max-width:420px) {
                                font-size: 26px;
                            }
                        }
                    }
                }

                .content{
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    
                    .img-slider {
                        position: absolute;
                        z-index: 5;
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                    }

                    video {
                        position: absolute;
                        z-index: 5;
                        object-fit: fill;
                        width: 100%;
                        height: 100%;
                    }

                    .next-image {
                        position: absolute;
                        bottom:8%;
                        right: 2%;
                        width: 176px;
                        height: auto;
                        padding: 10px 20px;
                        box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.28);
                        border-radius: 6px;
                        background-color: white;
                        box-sizing: content-box;
                        z-index: 10;
                        img {
                            object-fit: contain;
                            width: 100%;
                            height: 100%;
                        }
                        p{
                            color: var(--y-grey16);
                            font-family: PoppinsRegular;
                            font-size: 16px;
                            text-align: center;
                            text-transform: uppercase;
                        }

                        @media only screen and (max-width:920px) {
                            width: 127px;
                        }
                        @media only screen and (max-width:720px) {
                            width: 120px;
                            padding: 10px 10px;
                            p{
                                font-size: 14px;
                            }
                        }

                        @media only screen and (max-width:420px) {
                            width: 93px;
                            padding: 5px;
                            p{
                                font-size: 12px;
                            }
                        }
                    }
                    .counter-practice {
                        position: absolute;
                        top:10px;
                        right: 2%;
                        display: grid;
                        width:auto;
                        grid-template-columns: 1fr;
                        padding: 10px;
                        z-index:10;
                        .right-block{
                            display: flex;
                            flex-direction: column;
                            font-family: PoppinsBold;
                            color: var(--y-grey3);
                            font-size: 50px;
                            margin-left: auto;
                            p{
                                margin: 0;
                                line-height: 50px;
                                text-transform: uppercase;
                                &.countdown {
                                    font-size: 40px;
                                }
                               
                            }
                            
                            @media only screen and (max-width:920px) {
                                p{
                                    &.countdown {
                                        font-size: 36px;
                                    }
                                }
                                
                            }
                            @media only screen and (max-width:619px) {
                                p{
                                    &.countdown {
                                        font-size: 30px;
                                    }
                                }
                                
                            }
                        }
            
                        @media only screen and (max-width:1150px) {
                            grid-template-columns: 1fr;
                        }
                    }

                    .loading-container{
                        width: 50%;
                        height: auto;

                        position: absolute;
                        top: 65%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        z-index: 9999999;

                        p{
                            font-family: PoppinsRegular;
                            color: var(--y-grey6);
                            font-size: 28px;
                            text-align: center;

                            @media only screen and (max-width:960px) {
                                font-size: 22px;
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width:1024px) {
                padding: 0;
            }

            
        }

        .progress-section {
            display: grid;
            grid-template-columns: min-content 1fr auto;
            align-items: center;
            margin-bottom: 15px;
            width: 100%;
            grid-gap: 30px;
            padding: 0 20px;

            position: relative;

            transition: opacity 0.5s;
            
            .text {
                
                @media only screen and (max-width:1024px) {
                    display: none;
                    order: 3;
                }
                
            }
            
            .progress-bar{
                background: #FDFEFF;
                border: 2px solid #DCE0DF;
                box-sizing: border-box;
                border-radius: 5px;
                display: inline-block;
                padding: 10px;
                width: 100%;
                align-self: center;

                cursor: pointer;

                .progress-bar-preview{
                    width: 20%;
                    min-width: 150px;

                    aspect-ratio: 16 / 9;

                    background-color: black;

                    border-radius: 5px;
                    overflow: hidden;
                    border: 2px solid #30425a;

                    position: absolute;
                    bottom: 3rem;

                    z-index: 18;

                    // @media only screen and (max-width:619px) {
                    //     display: none;
                    // }
                }

                .progress-inner{
                    background:var(--brand-secondary);
                    height: 10px;
                    @media only screen and (max-width:950px) {
                        height: 6px;
                    }
                }

                .progress-bar-seeker{
                    border: 1px solid #dce0dfab;
                }
        
                @media only screen and (max-width:1024px) {
                    max-width: 900px;
                    margin: 0 auto;
                    padding: 8px 20px;
                    order: 2;
                }
        
                @media only screen and (max-width:950px) {
                    padding: 2px;
                }
                
            }

            .percent {
                align-self: center;
                @media only screen and (max-width:1024px) {
                    text-align: center;
                    font-size: small;
                    padding: 8px;
                    order: 1;
                }
            }

            @media only screen and (max-width:950px) {
                grid-gap: 0px;
                margin-bottom: 0px;
                height: 35px;
            }
        }
       
    }
}


.wrapper-actions-buttons {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    z-index: 20;
        
}

  video::-internal-media-controls-overlay-cast-button {
    display: none;
}