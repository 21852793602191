.plans-block-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    // padding-top: 60px;
    // padding-bottom: 60px;
    .plan-block {
        padding: 20px;
        flex-basis: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        background-color: white;
        position: relative;
        display: flex;
        flex-direction: column;
        .question-text{
            font-family: PoppinsBold;
            color: var(--y-grey3);
            font-size: 14px;
        }
        .plan-block-header {
            padding-top: 40px;
            padding-bottom: 40px;
            text-align: center;
            .title {
                font-size: 30px;
                line-height: 40px;
                color: var(--y-grey3);
                text-transform: uppercase;
                font-family: PoppinsSemiBold;
                margin-bottom: 20px;
                @media only screen and (max-width:590px) {
                    font-size:28px;
                    line-height: 38px;
                }
            }
            .price {
                font-family: PoppinsSemiBold;
                font-size: 42px;
                line-height: 40px;
                color: var(--y-grey3);
                .abbreviation {
                    font-size: 16px;
                }
                &.sub {
                    font-size: 16px;
                    color: var(--grey4);
                    &.lined {
                        text-decoration: line-through;
                    }  
                }
                @media only screen and (max-width:590px) {
                    font-size:32px;
                    line-height: 30px;
                }
            }

            @media only screen and (max-width:590px) {
                    padding-top: 20px;
                    padding-bottom: 20px;
            }
        }
        .plan-block-body {
            padding: 30px 0;
            
            .rowed{
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 20px;
                
                .col {
                    flex-basis: 0;
                    -webkit-box-flex: 1;
                    flex-grow: 1;
                    max-width: 100%;
                    font-family: PoppinsRegular;
                    p {
                        font-size: 16px;
                        color: var(--grey3);
                        @media only screen and (max-width:420px) {
                            font-size: 12px;
                        }
                    }
                    &.col1 {
                        flex: 0 0 40px;
                        max-width: 40px;
                    }
                    .f-icon-icono_4 {
                        &::before {
                            color: var(--y-green1);
                        }
                    }

                    .f-icon-cancel-1 {
                        &::before {
                            color: var(--y-red1);
                        }
                    }
                }
            }
            @media only screen and (max-width:590px) {
                padding: 20px 0;
            }
        }
        @media only screen and (max-width:590px) {
            padding:0;
        }
    }

    @media only screen and (max-width:590px) {
        padding-top: 0px;
        padding-bottom: 0;
    }
  
}