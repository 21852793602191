.faq-box {

    max-width: 820px;
    margin: 40px auto;
    width: 100%;
    
    .faq-row {
        
        color: var(--grey6);
        margin-bottom: 8px;
        .faq-header{
            padding: 20px 30px;
            font-size: 22px;
            line-height: 1.2;
            font-family: RobotoBold;
            position: relative;
            border-radius: 8px;
            .faq-title {
                padding-right: 40px;
            }
            .closure {
                position: absolute;
                display: inline-block;
                right: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(0deg);
                &.rotate {
                    transform: translateY(-50%) rotate(-45deg);
                    
                }
            }
            @media only screen and (max-width: 720px) {
                font-size: 22px;
            }
            @media only screen and (max-width: 520px) {
                font-size:18px;
                padding: 15px;
            }
        }
        .faq-body {
            padding: 30px;
            font-family: RobotoRegular;
            font-size: 20px;
            display: none;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            &.active {
                display: block;
            }
            @media only screen and (max-width: 720px) {
                font-size: 18px;
                }
            @media only screen and (max-width: 520px) {
                font-size:15px;
                padding: 15px;
            }
        }

        &.evenColor {
            .faq-header {
                background-color: var(--y-grey3);
            }
            
            .faq-body {
                background-color: rgba(49, 66, 90, 0.81);

            }
        }

        &.oddColor  {
            .faq-header {
                background-color: #116985;
            }
            .faq-body {
                background-color: rgba(0, 147, 156, 0.64)
            }
        }

        &.active {
            .faq-header{ 
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }
            
        }
        
    }

    &.alternative {
        .faq-row { 
            background-color: var(--grey5);
            color: var(--grey3);
            .faq-header{
                border-bottom: solid 2px white;
            }
        }
        
    }

    @media only screen and (max-width: 520px) {
        margin: 40px auto;
    }
}
