.wrapper-guide {
    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    position: relative;
    .inner {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        padding: 20px;

        .action-back-desktop {
            margin-top: 32px;
            position: relative;
            padding-bottom: 32px;
            cursor: pointer;
            .back-text{
                font-family: RobotoBold;
                font-size: 13px;
                color: var(--grey3);
                margin-left: 15px;
                line-height: 13px;
                display: inline-block;
                position: relative;
                top: -3px;
            }
            
            @media only screen and (max-width: 920px) {
                display: none;
            }
        }

        .action-back-mobile {
            display: none;
            margin-top: 32px;
            position: relative;
            padding-bottom: 32px;
            cursor: pointer;
            @media only screen and (max-width: 920px) {
                font-size: 16px;
                margin-top: 0;
                padding: 0 20px 20px 20px;
                display: block;
            }
        }
        @media only screen and (max-width: 920px) {
            padding-top: 48px;
            
        }

    }
    
}