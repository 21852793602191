.practice-target-wrapper {
    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    position: relative;
    .inner {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        min-height: 100%;
        flex-direction: column;

        .pr-nav {
            display: flex;
            border-bottom: 1px solid lightgray;
            padding: 0 20px;
            justify-content: space-between;
            font-family: PoppinsSemiBold;
            font-size: 14px;
            align-items: center;
            height: 50px;
            .pr-item {
                flex-basis: 0%;
                flex-shrink: 1;
                flex-grow: 0.48;
                color: var(--y-grey3);
                font-size: 1em;
                display: flex;
                justify-content: center;
                text-align: center;
                height: 100%;
                align-items: center;
                position: relative;
                cursor: pointer;
                &.active {
                    &:before {
                        content: "";
                        bottom: -3px;
                        width: 100%;
                        height: 4px;
                        background-color: var(--y-grey3);
                        position: absolute;
                    }
                }
            }

            @media only screen and (min-width: 921px) {
               display: none;
            }
        }

        .pr-flex {
            flex: 1;//parent with min-height no inherit height to child: fix here: https://stackoverflow.com/questions/8468066/child-inside-parent-with-min-height-100-not-inheriting-height/8468131
            display: flex;
            padding: 5vw 30px;
            align-items: stretch;

            .pr-aside {
                flex-grow: 0;
                flex-shrink: 0;
                width: 215px;
                padding: 10px;
                @media only screen and (max-width: 920px) {
                    display: none;
            
                }
            }
            .pr-content {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: auto;
                padding-left: 3vw;
                
            }

            @media only screen and (max-width:520px) {
                padding: 5vw 20px;
            }
  
        }

        .btn-practice-wrapper-a {
            padding: 32px 0;
            @media only screen and (max-width: 420px) {
                text-align: center;
                padding: 0 28 48px 0;
             }
        }
        .practice-target-header {
            
            .practice-target-header-title {
                font-size: 22px;
                text-align: left;
                text-transform: uppercase;
                font-family: PoppinsSemiBold;
                color: var(--y-grey3);
                
                 @media only screen and (max-width: 420px) {
                    font-size: 18px;
                 }
            }
            .practice-target-header-subtitle {
                font-family: PoppinsRegular;
                font-size: 14px;
                text-align: left;
                padding-bottom: 60px;
                color: var(--y-grey3);
               
                @media only screen and (max-width: 420px) {
                    font-size: 14px;
                 }
                span {
                    color: var(--y-green1);
                }

                @media only screen and (max-width: 420px) {
                    padding-bottom: 30px;
                 }
            }

            @media only screen and (max-width: 918px) {
                padding-top: 0;
             }
        }

        .practice-target-grid {
            display: flex;
            flex-wrap: wrap;
            
            .item {
                flex-shrink: 1;
                flex-grow: 1;
                padding: 20px;
                margin-bottom: 20px;
                position: relative;
                &.left-i{
                    flex-basis: 25%;
                    &::before{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 5px;
                        background-color: var(--y-grey12);
                        width: 10%;
                        box-sizing: content-box;
                        border: solid 4px white;
                        @media only screen and (max-width: 720px) {
                            display: none;
                         }
                    }

                    @media only screen and (max-width: 1124px) {
                        flex-basis: 50%;
                     }
                }
                &.right-i {
                    flex-basis: 25%;
                    @media only screen and (max-width: 1124px) {
                        flex-basis: 50%;
                     }
                }
                &.blue-b {
                    background-color: var(--y-grey3);
                    .w-image {
                        border: 3px dashed var(--y-green1);
                    }
                }
                &.grey-b {
                    background-color: var(--y-grey10);
                }
                &.grey-2-b {
                    background-color: var(--grey5);
                    .w-image {
                        border: 3px dashed var(--y-grey3);
                    }
                }
                .item-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    .text {
                        font-family: PoppinsRegular;
                        color: var(--y-grey6);
                        font-size: 18px;
                        text-align: center;
                        display: block;
                       
                         @media only screen and (max-width: 560px) {
                            font-size: 17px;
                         }

                         @media only screen and (max-width: 420px) {
                            font-size: 16px;
                            text-align: left;
                         }
                    }
                    .w-image {
                        display: flex;
                        align-items: center;
                        padding: 5px 40px;
                        justify-content: center;
                        
                        img {
                            width: 104px;
                            height: 100%;
                            object-fit: contain;
                            
                        }

                    }

                    .secondary-text {
                        font-family: PoppinsRegular;
                        color: var(--y-green1);
                        font-size: 18px;
                        text-align: center;
                        text-transform: uppercase;
    
                        @media only screen and (max-width: 560px) {
                            font-size: 17px;
                         }

                         @media only screen and (max-width: 420px) {
                            font-size: 16px;
                            text-align: left;
                         }
                    }
                    
                }
                @media only screen and (max-width: 560px) {
                    padding: 20px;
                 }   
            }

            @media only screen and (max-width: 720px) {
                flex-direction: column;
             }
        }
       
    }
}