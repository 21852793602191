#root {
    height: 100%;
    width: 100%;
}

body, html {
    width: 100%;
    height: 100%;
    min-width: 315px!important;
    overflow-y: visible;
}

body {
    -webkit-font-smoothing: antialiased;
}

body ::-webkit-scrollbar {
    -webkit-appearance: inherit!important;
    width:5px;
}

*{
    outline: 0;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
 input:-webkit-autofill:hover,
 input:-webkit-autofill:focus,
 input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    box-shadow: 0 0 0px 1000px white inset !important;
  }


//////////////////////////////////////////////////////////// Overrides /////////////////////////////////////////////////////////////////////////////////////

// Overrides charts
g[aria-labelledby$="-title"] {
    display: none;
}

// Overrides datepicker
.ui.icon.input[data-testid='datepicker-input'] {
    width: 225px;
}

//overrides error message label

.ui.basic.red.label {
    background:#FF325D!important;
    display: block!important;
    color: white!important;
    font-family: PoppinsRegular;
    font-weight: 400!important;
}

//custom css for react-toastify

.Toastify__toast{
    font-family: PoppinsRegular;
}

.Toastify__toast--success {

    &.r-toast-success {
        background: var(--y-green1);
    }

    &.r-toast-error {
        background: var(--red4);
    }
}

    video::-webkit-media-controls-start-playback-button {
    display: none !important;
  }


body.hideScroll * ::-webkit-scrollbar {

    display: none!important;
}

blockquote {
    margin: 0;
}

strong {
    font-family: var(--font-primary);
}
.rotate225{
    transform: rotate(225deg);
}

.rotate45{
    transform: rotate(45deg);
}