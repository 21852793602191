.top-bar {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    height: 48px;
    background-color: var(--y-grey3);
    flex-shrink: 0;
    .content-top-bar {
        display: flex;
        height: 100%;
        
    }
    .hambuger-menu {
        margin-left: 10px;
        i {
            &:before{
                color: rgba(255, 255, 255, 0.8)
            }
        }
    }
    .arrow-top-bar {
        margin: 0 10px;
        display: flex;
        align-items: center;
        height: 100%;
        flex: 1;
        i {
            font-size: 25px;
            height: auto;
            &:before {
                color:rgba(255, 255, 255, 0.8)
            }
        }
    }
    .logo-top-bar {
        margin: 0 10px;
        display: flex;
        align-items: center;
        height: 100%;
        flex: 6;
        justify-content: center;
        overflow:hidden;
        text-overflow: ellipsis;
        img {
            max-width: 100%;
            max-height: 30px;
        }
        p {
            font-family: PoppinsSemiBold;
            color: white;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            text-align: center;
        }

    }
    .profile-top-bar {
        // background-color: blue;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        flex: 1;
        max-width: 40px;
        i {
            font-size: 25px;
            &:before {
                color: rgba(255, 255, 255, 0.8);
            }
        }

    }

    .basket-top-bar {
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        position: relative;
        flex: 1;
        max-width: 40px;
        i {
            position: relative;
            font-size: 25px;
            &:before {
                color: rgba(255, 255, 255, 0.8);
            }
        }
        
        .basket-budget {
            position: absolute;
            right: 0px;
            top: 8px;
            display: flex;
            min-width: 15px;
            min-height: 15px;
            padding: 0 2px;
            box-sizing: border-box;
            color: white;
            background-color: var(--y-green1);
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            margin: 0;
            
            p{
                margin: 0;
            }
        }
    }

    

    @media only screen and (min-width:921px) {
        display: none;
        
    }
}

.basket-empty {
    color: var(--y-grey3);
    font-family: PoppinsSemiBold;
}