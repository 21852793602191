.pr-menu-items {
    font-size: 14px;
    position: sticky;
    top: 20px;
    .item {
        padding: 5px 10px;
        font-size: 1em;
        color: var(--y-grey3);
        font-family: PoppinsSemiBold;
        position: relative;
        margin-bottom: 10px;
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        cursor: pointer;
        &:before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            background-color: var(--y-grey3);
            bottom: 0;
            opacity:0;
            transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        }
        &.active {
            &::before{
                opacity: 1;
            }
        }
        &:hover {
            color: var(--y-green1);
            &::before{
                background-color: var(--y-green1);
            }
        }
        span {
            margin-left: 10px;
        }
    }
}