.main-background {

    width: 100%;
    position: absolute;
    height: 100%;
    background-color: #EBEDEF;

    &:after{
        content: "";
        -webkit-clip-path: ellipse(51% 99% at 12% 42%);
        clip-path: ellipse(51% 99% at 12% 48%);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 1;
        @media only screen and (max-width: 720px) {
            display: none;
        }
    }

    &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
         background-image: url('../../../assets/img/meteoros_2.png');
        background-repeat: no-repeat;
        background-position: top right;
        @media only screen and (max-width: 720px) {
            display: none;
        }
    }

    @media only screen and (max-width: 720px) {
        background: transparent;
    }
}


.wrapper-payment {

    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 5%;
    min-height: 100vh;
    grid-gap: 20px;
    position: relative;
    z-index: 2;
    
    div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .first-col{
        div{
            margin-bottom: 32px;
            @media only screen and (max-width: 720px) {
                margin-bottom: 10px;
            }
        }
        flex-direction: column;
        align-items: flex-start;

        .title {
            font-family: PoppinsMedium;
            font-size: 64px;
            color: var(--y-grey3);
            text-align: left;
            @media only screen and (max-width: 820px) {
                font-size: 10vmin;
              }
              @media only screen and (max-width: 420px) {
                font-size: 32px;
              }
        }
        .subtitle {
            font-family: PoppinsRegular;
            font-size: 26px;
            color: var(--y-grey3);
            text-align: left;
            @media only screen and (max-width: 820px) {
                font-size: 3vmin;
              }
              @media only screen and (max-width: 420px) {
                font-size: 18px;
              }
        }
        button{
            @media only screen and (max-width:420px) {
                font-size: 18px;
            }
        }
    }
    .second-col{
        
        img {
            max-width: 100%;
            height: auto;
        }
    }

    @media only screen and (max-width: 720px) {
        grid-template-columns: 1fr;

      }
}
