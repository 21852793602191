.faq-wrapper {
    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    .footer-section  {
        margin-top: auto;
    }
    &.hasExtraTopBar {
        height: calc(100% - var(--extra-nav-height));
    }
    
    .inner{
        max-width: 1440px;
        margin: 0 auto;
        padding: 32px 20px;
        h1 {
            text-align: center;
            margin-top: 40px;
            text-transform: uppercase;
            font-family: PoppinsSemiBold;
            @media only screen and (max-width:920px) {
                margin-top: 0px;
            }
        }
        form {
            max-width: 820px;
            margin: 40px auto;
        }

        .center-content {
            max-width: 820px;
            margin: 0 auto;

            p{
            
                color: var(--grey3);
                font-size: 16px;
                margin: 10px 0;
                
            }
    
            p{
                &:first-of-type {
                    margin: 20px 0 10px 0;            
                }
            }

            .go-back-mobile {
                position: absolute;
                top: 0px;
                left: 20px;
            }
        }

        .categories-box {

            max-width: 820px;
            margin: 40px auto;
            width: 100%;
            .category-row {
                margin-bottom: 8px;
                padding: 20px 30px;
                font-size: 22px;
                line-height: 1.2;
                font-family: PoppinsSemiBold;
                position: relative;
                border-radius: 8px;
                border: solid 2px var(--y-grey17);
                .title {
                    padding-right: 40px;
                    color: var(--y-grey3);
                }
                .action-icon {
                    position: absolute;
                    right: 20px;
                    top: 22px;
                    cursor: pointer;
                    &::before {
                        color: var(--y-grey3);
                    }
                }

                @media only screen and (max-width:720px) {
                    font-size: 18px;
                }

                @media only screen and (max-width:480px) {
                    font-size: 16px;
                }
            }

            @media only screen and (max-width:480px) {
                margin: 32px auto;
            }
        }
    }
    
}