.footer-section {
    background: black;
    // border-bottom: solid 15px var(--grey2);
    position: relative;
    .footer-extra {
        background-color: var(--y-grey18);
        .footer-extra-content {
            margin: 0 auto;
            max-width: 1140px;
            padding: 35px 20px;
            .footer-exta-flex-wrap {
                display: flex;
                width: 100%;
                .f-text{
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: calc(70% - 40px);
                    margin: 0 20px 20px 20px;
                    color: var(--y-grey6);
                }
                .f-images {
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: calc(30% - 40px);
                    display: flex;
                    margin: 0 20px 20px 20px;
                    
                    div {
                       margin: 0 5px;
                        img {
                            width: 120px;
                            object-fit: contain;
                        }
                        
                    }
                    @media only screen and (max-width: 465px) {
                        flex-wrap: wrap;
                    }
                    
                }

                @media only screen and (max-width: 980px) {
                    flex-direction: column;
                }
            }
        }
    }

    .footer-content {
        margin: 0 auto;
        max-width: 1140px;
        padding: 35px 20px;
        .title {
            font-family: RobotoBold;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: -0.015em;
            color: var(--grey3);
        }
        .content {
            display: flex;
            font-size: 14px;
            color: var(--grey3);
            width: 100%;
            flex-wrap: wrap;
            .footer-row {

                    flex:1;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    align-content: center;
                    margin-right: 6px;

                p{
                    cursor: pointer;
                    color: var(--y-green1);
                }
                a{
                    text-decoration: none;
                    color: inherit;
                }

                &.expanded {
                        text-align: center;
                        flex-grow: 1;
                        flex-shrink: 0;
                        flex-basis: 100%;
                        margin-top: 30px;
                        p {
                            color: var(--y-grey16);
                            cursor: initial;
                        }
                        @media only screen and (max-width: 420px) {
                            text-align: left;
                        }
                }
            }

            @media only screen and (max-width: 420px) {
                font-size: 13px;
            }

        }

        @media only screen and (max-width: 820px) {
            padding: 20px 20px;
        }
        
    }

    &.alternative {
        background: white;
    }
    &.branded {
        background-color: var(--y-grey3);
        .content {
            color: white;
        }
        border-width: 0;
    }
}