.side-bar-profile {
    background-color:white;
    top: 0;
    bottom: 0;
    overflow: auto;
    width:var(--drawer-width);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    right: calc(-1 * var(--drawer-width));
    z-index: 14;
    transition: right 0.5s ease-in-out;
    padding-top: 10px;
        .sp-close {
            position: absolute;
            right: 10px;
            top: 10px;
            i {
                font-size: 22px;
                &:before {
                    color: var(--y-grey3);
                }
            }
        }
        .sp-avatar {
            padding: 20px;
            border-bottom: solid 1px #979797;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-shrink: 0;
            .sp-image {
                width: 51px;
                height: 51px;
                border-radius: 51px;
                background-color: #d8d8d8;
                margin-bottom: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                i {
                    font-size: 30px;
                    &:before {
                        color: white;
                    }
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .sp-name {
                font-family: PoppinsSemiBold;
                font-size: 14px;
                color: var(--y-grey3);
                
            }
        } 
        .sp-menu-items {
            
            width: 100%;
            .item {
                display: block;
                padding: 15px 20px;
                color: var(--y-grey3)!important;
                font-family: PoppinsRegular;
                font-size: 14px;
                border-bottom: solid 1px #979797;
                a {
                    text-decoration: none;
                    color: inherit;
                    position:relative;
                    
                }
                i {
                    margin-right: 10px;
                    &::before{
                        color: var(--y-grey3);
                    }
                    
                }
                svg {
                    margin-right: 10px;
                }
                &.active {
                    font-family: PoppinsBold;
                }
            }
        }

    &.expanded {
        
        @media only screen and (max-width: 920px) {
            right: 0;
         }
         
    }
}

.dimmered {
    position: absolute;
    background: rgba(0,0,0,0.5);
    top: 0;
    width: 100%;
    z-index: 13;
    min-height: 100vh;

}

.toogle-icon {
    position: relative;
    padding: 15px;
    top: 0;
    z-index: 8;
    background: white;
    @media only screen and (min-width: 920px) {
        display: none;
     }
}