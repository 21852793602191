
@font-face {
    font-family: 'RobotoBold';
    src: url('../fonts/Roboto/Roboto-Bold.eot');
    src: url('../fonts/Roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto/Roboto-Bold.woff') format('woff'),
        url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype'),
        url('../fonts/Roboto/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoRegular';
    src: url('../fonts/Roboto/Roboto-Regular.eot');
    src: url('../fonts/Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype'),
        url('../fonts/Roboto/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoLight';
    src: url('../fonts/Roboto/Roboto-Light.eot');
    src: url('../fonts/Roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto/Roboto-Light.woff2') format('woff2'),
        url('../fonts/Roboto/Roboto-Light.woff') format('woff'),
        url('../fonts/Roboto/Roboto-Light.ttf') format('truetype'),
        url('../fonts/Roboto/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'PoppinsMedium';
    src: url('../fonts/Poppins/Poppins-Medium.eot');
    src: url('../fonts/Poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Medium.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'PoppinsRegular';
    src: url('../fonts/Poppins/Poppins-Regular.eot');
    src: url('../fonts/Poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Regular.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PoppinsBold';
    src: url('../fonts/Poppins/Poppins-Bold.eot');
    src: url('../fonts/Poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Bold.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PoppinsSemiBold';
    src: url('../fonts/Poppins/Poppins-SemiBold.eot');
    src: url('../fonts/Poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BebasNeueRegular';
    src: url('../fonts/BebasNeue/BebasNeue-Regular.eot');
    src: url('../fonts/BebasNeue/BebasNeue-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BebasNeue/BebasNeue-Regular.woff2') format('woff2'),
        url('../fonts/BebasNeue/BebasNeue-Regular.woff') format('woff'),
        url('../fonts/BebasNeue/BebasNeue-Regular.ttf') format('truetype'),
        url('../fonts/BebasNeue/BebasNeue-Regular.svg#BebasNeue-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BebasNeueBold';
    src: url('../fonts/BebasNeue/BebasNeue-Bold.eot');
    src: url('../fonts/BebasNeue/BebasNeue-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BebasNeue/BebasNeue-Bold.woff2') format('woff2'),
        url('../fonts/BebasNeue/BebasNeue-Bold.woff') format('woff'),
        url('../fonts/BebasNeue/BebasNeue-Bold.ttf') format('truetype'),
        url('../fonts/BebasNeue/BebasNeue-Bold.svg#BebasNeue-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello/fontello.eot?90551006');
    src: url('../fonts/fontello/fontello.eot?90551006#iefix') format('embedded-opentype'),
         url('../fonts/fontello/fontello.woff2?90551006') format('woff2'),
         url('../fonts/fontello/fontello.woff?90551006') format('woff'),
         url('../fonts/fontello/fontello.ttf?90551006') format('truetype'),
         url('../fonts/fontello/fontello.svg?90551006#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
  }

   [class^="f-icon-"]:before, [class*="f-icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
   
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    color: #828282;//grey3
    /* opacity: .8; */
   
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
   
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
   
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
   
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
   
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }

  .f-icon-basket:before { content: '\e800'; } /* '' */
  .f-icon-edit:before { content: '\e801'; } /* '' */
  .f-icon-cancel:before { content: '\e802'; } /* '' */
  .f-icon-pause:before { content: '\e803'; } /* '' */
  .f-icon-target:before { content: '\e804'; } /* '' */
  .f-icon-icono_5:before { content: '\e805'; } /* '' */
  .f-icon-icono_4:before { content: '\e806'; } /* '' */
  .f-icon-icono_3:before { content: '\e807'; } /* '' */
  .f-icon-balance_6:before { content: '\e808'; } /* '' */
  .f-icon-balance_1:before { content: '\e809'; } /* '' */
  .f-icon-icono_2:before { content: '\e80a'; } /* '' */
  .f-icon-elasticity:before { content: '\e80b'; } /* '' */
  .f-icon-icono_6:before { content: '\e80c'; } /* '' */
  .f-icon-icono_7:before { content: '\e80d'; } /* '' */
  .f-icon-icono_1:before { content: '\e80e'; } /* '' */
  .f-icon-right-open-1:before { content: '\e80f'; } /* '' */
  .f-icon-icono_10:before { content: '\e810'; } /* '' */
  .f-icon-icono_8:before { content: '\e811'; } /* '' */
  .f-icon-icono_9:before { content: '\e812'; } /* '' */
  .f-icon-left-open-1:before { content: '\e813'; } /* '' */
  .f-icon-diagnostico-en-lineas:before { content: '\e814'; } /* '' */
  .f-icon-diagnostico-en-relleno:before { content: '\e815'; } /* '' */
  .f-icon-inicio-en-lineas:before { content: '\e816'; } /* '' */
  .f-icon-inicio-en-relleno:before { content: '\e817'; } /* '' */
  .f-icon-practica-en-linea:before { content: '\e818'; } /* '' */
  .f-icon-practica-en-relleno:before { content: '\e819'; } /* '' */
  .f-icon-proximo-objetivo:before { content: '\e81a'; } /* '' */
  .f-icon-info-circled:before { content: '\e81b'; } /* '' */
  .f-icon-google:before { content: '\e81c'; } /* '' */
  .f-icon-candado-abierto:before { content: '\e81d'; } /* '' */
  .f-icon-candado-cerrado:before { content: '\e81e'; } /* '' */
  .f-icon-elasticidad:before { content: '\e81f'; } /* '' */
  .f-icon-tono:before { content: '\e820'; } /* '' */
  .f-icon-icono_13:before { content: '\e821'; } /* '' */
  .f-icon-icono_14:before { content: '\e822'; } /* '' */
  .f-icon-icono_15:before { content: '\e823'; } /* '' */
  .f-icon-icono_16:before { content: '\e824'; } /* '' */
  .f-icon-balance_2:before { content: '\e825'; } /* '' */
  .f-icon-balance_3:before { content: '\e826'; } /* '' */
  .f-icon-icono_17:before { content: '\e827'; } /* '' */
  .f-icon-flecha_4:before { content: '\e828'; } /* '' */
  .f-icon-balance_4:before { content: '\e829'; } /* '' */
  .f-icon-visa:before { content: '\e82a'; } /* '' */
  .f-icon-cw:before { content: '\e82b'; } /* '' */
  .f-icon-sepa:before { content: '\e82c'; } /* '' */
  .f-icon-mastercard:before { content: '\e82d'; } /* '' */
  .f-icon-giropay:before { content: '\e82e'; } /* '' */
  .f-icon-bancontact:before { content: '\e82f'; } /* '' */
  .f-icon-apple-pay:before { content: '\e830'; } /* '' */
  .f-icon-amex:before { content: '\e831'; } /* '' */
  .f-icon-alipay:before { content: '\e832'; } /* '' */
  .f-icon-proximo-objetivo-1:before { content: '\e833'; } /* '' */
  .f-icon-historico:before { content: '\e834'; } /* '' */
  .f-icon-guia-matriz:before { content: '\e835'; } /* '' */
  .f-icon-help-circled:before { content: '\e836'; } /* '' */
  .f-icon-icono_11:before { content: '\e837'; } /* '' */
  .f-icon-balance_7:before { content: '\e838'; } /* '' */
  .f-icon-doc-text-1:before { content: '\e839'; } /* '' */
  .f-icon-practica:before { content: '\e83a'; } /* '' */
  .f-icon-practica_lineas:before { content: '\e83b'; } /* '' */
  .f-icon-facebook:before { content: '\e83c'; } /* '' */
  .f-icon-mail:before { content: '\e83d'; } /* '' */
  .f-icon-minus-circled:before { content: '\e83e'; } /* '' */
  .f-icon-quote:before { content: '\e83f'; } /* '' */
  .f-icon-cancel-1:before { content: '\e840'; } /* '' */
  .f-icon-acompañamiento-en-video:before { content: '\e841'; } /* '' */
  .f-icon-credit-card:before { content: '\e842'; } /* '' */
  .f-icon-descargar-secuencias:before { content: '\e843'; } /* '' */
  .f-icon-icono_12:before { content: '\e844'; } /* '' */
  .f-icon-practica-alternativas:before { content: '\e845'; } /* '' */
  .f-icon-suscripcion:before { content: '\e846'; } /* '' */
  .f-icon-diagnóstico-corporal:before { content: '\e847'; } /* '' */
  .f-icon-práctica-personalizada:before { content: '\e848'; } /* '' */
  .f-icon-transformación-continua:before { content: '\e849'; } /* '' */
  .f-icon-tu-matriz-briómetrica:before { content: '\e84a'; } /* '' */
  .f-icon-book:before { content: '\e84b'; } /* '' */
  .f-icon-diana:before { content: '\e852'; } /* '' */
  .f-icon-previsualizar-objetivos:before { content: '\e853'; } /* '' */
  .f-icon-descarga:before { content: '\e859'; } /* '' */
  .f-icon-rss:before { content: '\f09e'; } /* '' */
  .f-icon-money:before { content: '\f0d6'; } /* '' */
  .f-icon-bell-alt:before { content: '\f0f3'; } /* '' */
  .f-icon-angle-right:before { content: '\f105'; } /* '' */
  .f-icon-ticket:before { content: '\f145'; } /* '' */
  .f-icon-cc-visa:before { content: '\f1f0'; } /* '' */
  .f-icon-cc-mastercard:before { content: '\f1f1'; } /* '' */
  .f-icon-cc-amex:before { content: '\f1f3'; } /* '' */
  .f-icon-bell-off:before { content: '\f1f6'; } /* '' */
  .f-icon-bell-off-empty:before { content: '\f1f7'; } /* '' */
  .f-icon-twitter-bird:before { content: '\f303'; } /* '' */
  .f-icon-googleplus-rect:before { content: '\f309'; } /* '' */
  .f-icon-linkedin:before { content: '\f31a'; } /* '' */