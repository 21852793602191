
.wrapper {

    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 375px) {
        align-items: flex-start;
    }
}

.i-back {
    display: inline-flex;
    margin-bottom: 10px;
    cursor: pointer;
    i {
        margin-right: 5px;
        height: auto;
        &:before {
            font-size: 25px;
            color:#242a42;
            opacity: 0.5;
        }
    }
    span {
        font-family: PoppinsRegular;
        font-size: 14px;
        color: var(--y-grey3);
    }
    @media only screen and (max-width: 920px) {
        display: none;
      }
    
    &.notHide{
        display: inline-flex!important;
    }
}