.y-notification {

    &.fixered {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 20;
        a {
            color: var(--y-grey3);
        }
        @media only screen and (max-width:920px) {
            top: 0;
            bottom: unset;
        }
    }
    
    background: #1fd5b9d1;
    color: white;
    font-size: 18px;
    font-family: PoppinsRegular;
    padding: 20px;
    text-align: center;
    b {
        font-family: PoppinsSemiBold;
    }

    @media only screen and (max-width:820px) {
        font-size: 16px;
    }

    @media only screen and (max-width:420px) {
        font-size: 14px;
    }
    
}