.cookies-section {
    z-index: 1000;
    position: fixed;
    right: 0;
    left: 0;
    bottom:0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);

    .cookies-text {
        display: flex;
        align-items: center;
        .cookies-message {
            padding: 10px 50px 15px 50px;
            margin: 0 auto;
            text-align: left;
            font-size: 13px;
            color: var(--grey2);
            .legal-cookies-link {
                text-decoration: underline;
                cursor: pointer;
            }

            @media only screen and (max-width: 420px) {
                padding: 10px 50px 15px 25px;
                
            }
        }
    }

    .cookies-closure {
        display: inline-block;
        position: absolute;
        right: 15px;
        top: 0;
        padding-top: 10px;
        font-size: 16px;
    }

    &.alternative {
        background-color: rgba(0, 0, 0, 0.9);
        .cookies-message {
            color: var(--grey6);
        }
        i {
            color: var(--grey6);
        }
    }
}