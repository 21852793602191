.fixed-banner {
    min-height: 50px;
    background-color: var(--primary-colors-blue-4-30-2);
    text-align: center;
    font-family: PoppinsRegular;
    color: #313131;
    padding: 15px;
    position: relative;
    z-index: 10;
    flex-grow: 0;
    flex-shrink: 0;
    &__button {
        display: inline-block;
        border: solid 2px #313131;
        cursor: pointer;
        padding: 5px 10px;
        text-align: center;
        border-radius: 5px;
        margin-left: 20px;
        @media only screen and (max-width: 466px) {
            
            margin-top: 10px;
          }
          @media only screen and (max-width: 446px) {
            
            margin-left: 0px;
          }
    }
   
}