
.main-container {
    
    width: 100%;
    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    .inner-container {
        max-width: 1440px;
        padding: 32px 20px;
        margin: 0 auto;
        h1{
            font-family: RobotoBold;
        }
        p{
            font-family: RobotoRegular;
            color: var(--grey3);
            font-size: 14px;
            line-height: 1.6;
        }
        a {
            word-break: break-all;
        }
    }

    &.hasExtraTopBar {
        height: calc(100% - var(--extra-nav-height));
    }
    
}