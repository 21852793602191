.main-wrap {
    display: block;
    position: relative;
    height: calc(100% - var(--nav-height));
    overflow-x: hidden;
    background-color: white;
    z-index: 1;
    overflow-y: auto;

    .p-header {
        padding: 32px 0;
        max-width: 675px;

        @media (max-width: 420px) {
            padding: 20px 0;
        }
    }
}

.main-wrap .footer-section {
    position: relative;
    z-index: 2;
}

.block-separator {
    background: #fff;
}

.next-overlay {
    width: 100%;
    height: 100%;
    background-color:var(--y-grey3);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    p {
        font-family: PoppinsBold;
        font-size: 16px;
        color: var(--y-green2) !important;
        text-align: center;
    }
}

.proBox {
    background: #F4F4F4;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 30px;

    span.proSectionTitle {
        display: block;
        font-size: 1em;
        line-height: 1.25em;
        margin-bottom: 15px;
    }

    .proInfoBox {
        display: flex;
        padding: 15px;
        background-color: white;
        border-radius: 5px;
        margin-bottom: 15px;

        i, span.empty {
            font-weight: 500;
            font-size: 1em;
            line-height: 1.25em;
            color: #747474;
        }

        img.ui.image.avatar {
            height: 104px;
            width: 104px;
            object-fit: cover;
            margin-right: 0.75em;
            z-index: 2;
            margin-bottom: 20px;

            &.broken {
                z-index: 0;
            }

            @media (max-width: 720px) {
                height: 50px;
                width: 50px;
            }
        }

        span.initials {
            display: inline-block;
            position: absolute;
            color: black;
            margin-right: 0.75em;
            background-color: white;
            height: 104px;
            width: 104px;
            z-index: 1;
            border-radius: 50%;
            border: 1px solid #F4F4F4;
            font-size: 3em;
            padding: 0.5em;
            line-height: 1.4em;

            &.visible {
                z-index: 2;
            }

            @media (max-width: 720px) {
                height: 50px;
                width: 50px;
                font-size: 1.5em;
                padding: 0.5em;
                line-height: 1.2em;
            }
        }

        div.info {
            display: flex;
            justify-content: space-between;
            position: relative;
            vertical-align: top;
            top: 15px;
            width: calc(100% - 120px);

            span.title {
                display: inline-block;
                width: 100%;
                font-size: 1em;
                line-height: 1.25em;
                font-weight: 700;
            }

            span.description {
                display: inline-block;
                width: 100%;
                font-size: 1em;
                line-height: 1.25em;
            }

            span.seeMore {
                display: inline-block;
                font-size: 1em;
                line-height: 1.25em;
                color: #00B595;
                cursor: pointer;
            }

            @media (max-width: 720px) {
                top: 0;
                width: calc(100% - 62px);
            }
        }
    }

    .proPracticesBox, .proGroupsBox {
        padding: 15px;
        background-color: white;
        border-radius: 5px;
        margin-bottom: 15px;

        i, span.empty {
            font-weight: 500;
            font-size: 1em;
            line-height: 1.25em;
            color: #747474;
        }

        div.ui.divided.relaxed.list {
            div.item {
                padding-top: 0.75em;
                padding-bottom: 0.75em;

                img.ui.image {
                    height: 3em;
                    width: 3em;
                    object-fit: cover;
                    border-radius: 5px;
                    border: 2px double #F4F4F4;
                    z-index: 2;

                    &.broken {
                        z-index: 0;
                    }
                }

                span.initials {
                    display: inline-block;
                    position: absolute;
                    border-radius: 5px;
                    border: 3px double #F4F4F4;
                    padding: 0.8em;
                    color: black;
                    margin-right: 0.25em;
                    background-color: white;
                    height: 3em;
                    width: 3em;
                    z-index: 1;

                    &.visible {
                        z-index: 2;
                    }
                }

                .content {
                    width: calc(100% - 42px);

                    .header, .description {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    .proPracticesBox {
        div.ui.divided.relaxed.list {
            div.item {
                div.content {
                    width: calc(100% - 155px);
                }
            }
        }
    }
}